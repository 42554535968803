import _ from "lodash";
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import TableBox from "../table/TableBox";
import { useForm } from "react-hook-form";
import { Button, Separate } from "components/ui";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { ReservedTable, StoreTableList, StoreTableOblect } from "types";

interface AddReservation {
  onOpen: boolean;
  onClose: any;
  tableList: StoreTableList | [];
  tableAvalibate: any;
  createReservation: any;
  reservation?: ReservedTable;
  deleteReservation?: any;
}

const AddReservationModel = (props: AddReservation) => {
  const tableAvalibateHandler = props.tableAvalibate;
  const createReservationHandler = props.createReservation;
  const deleteReservationHandler = props.deleteReservation;
  const [tableList, setTableList] = React.useState<StoreTableList | []>([]);
  const [tableTabs, setTableTabs] = React.useState<any>();
  const [selectedTableType, setSelectedTableType] = React.useState("");
  const [selectedTable, setselectedTable] = React.useState<StoreTableOblect[]>(
    []
  );

  //DEFINE REACT-HOOK-FORM
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  //DEFINE REACT-HOOK-FORM END

  //CLOSE FUNCTION
  const closeHandler = () => {
    props.onClose();
    setSelectedTableType("");
    setTableList([]);
    setselectedTable([]);
    setTableTabs(undefined);
    setValue("guest_name", "");
    setValue("guest_number", "");
    setValue("guest_email", "");
    setValue("reservation_type", "");
    setValue("guests_number", "");
    setValue("reservation_date_time", "");
  };
  //CLOSE FUNCTION END

  //FUNCTION FOR TABLE AVAILABILITY UPDATE ONLY FOR RESERVATION UPDATE
  const onTableReservedUpdate = (id: string) => {
    const index = tableList.findIndex((obj) => obj._id === id);
    const updatedObject = { ...tableList[index], reservation: false };
    const updatedArray = [
      ...tableList.slice(0, index),
      updatedObject,
      ...tableList.slice(index + 1),
    ] as [];
    //
    const tablesDisplay = _.groupBy(
      updatedArray?.sort((a: any, b: any) =>
        a?.priority > b?.priority ? 1 : -1
      ),
      "table_location"
    );
    setTableList(updatedArray);
    setTableTabs(tablesDisplay);
  };
  //FUNCTION FOR TABLE AVAILABILITY UPDATE ONLY FOR RESERVATION UPDATE END

  //TABLE SELECTION
  const onSelectionTable = (checked: boolean, list: StoreTableOblect) => {
    if (checked) {
      if (list?.availability && !list?.reservation) {
        setselectedTable([...selectedTable, list]);
      } else {
        toast.warn("The table is already reserved.");
      }
    } else {
      setselectedTable(
        selectedTable.filter((table) => table._id !== list?._id)
      );
      onTableReservedUpdate(list?._id);
    }
  };
  //TABLE SELECTION END

  //FUNCTION FOR GET TABLE WITH AVAILABILITY
  const checkForTable = () => {
    if (
      getValues("reservation_date_time") === "" ||
      getValues("guests_number") === ""
    ) {
      toast.warn("Select the booking details and include a guest number.");
      return;
    }
    tableAvalibateHandler({
      bookingDate: getValues("reservation_date_time"),
      numberOfGuest: getValues("guests_number"),
    });
  };
  //FUNCTION FOR GET TABLE WITH AVAILABILITY END

  //CREATE | UPDATE TABLE RESERVATION
  const onTableReservation = handleSubmit((data: any) => {
    if (props.reservation) {
      createReservationHandler({
        ...data,
        _id: props.reservation?._id,
        selectedTable,
      });
    } else {
      createReservationHandler({ ...data, selectedTable });
    }
  });
  //CREATE | UPDATE TABLE RESERVATION END

  //FUNCTION FOR TOGGLE TABLE CATEGORY
  const onSelectTableCategoryTab = (list: string) => {
    setSelectedTableType(list);
    if (props.reservation === undefined) {
      setselectedTable([]);
    }
  };
  //FUNCTION FOR TOGGLE TABLE CATEGORY END

  React.useEffect(() => {
    //INITIAL VALUE
    const tablesDisplay = _.groupBy(
      props.tableList?.sort((a: any, b: any) =>
        a?.priority > b?.priority ? 1 : -1
      ),
      "table_location"
    );
    setSelectedTableType(Object.keys(tablesDisplay)[0]);
    setTableList(props.tableList);
    setTableTabs(tablesDisplay);

    //ADD DETAILS TO FORM IF RESERVATION IS IN UPDATE
    if (props.reservation !== undefined) {
      setValue("guest_name", props.reservation.guestName);
      setValue("guest_number", props.reservation.guestNumber);
      setValue("guest_email", props.reservation.guestEmail);
      setValue("reservation_type", props.reservation.reservation_type);
      setValue("guests_number", props.reservation.numberOfGuest);
      setValue(
        "reservation_date_time",
        moment(props.reservation.bookingDate).format("YYYY-MM-DDTHH:mm")
      ); //YYYY-MM-DDTHH:mm
      let sampleA = [];
      for (const table of props.reservation?.selectTable) {
        sampleA.push(table.tableId);
      }
      setselectedTable(sampleA);
    }
    //ADD DETAILS TO FORM IF RESERVATION IS IN UPDATE END
  }, [props.tableList, setValue, props.reservation]);

  return (
    <Transition appear show={props.onOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => closeHandler()}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 relative flex justify-center pb-4 text-xl font-medium leading-6"
                >
                  Add Reservation
                  <XIcon
                    className="absolute right-0 h-5 w-5 cursor-pointer"
                    onClick={() => closeHandler()}
                  />
                </Dialog.Title>
                <Separate dark={true} />
                <div className="my-10">
                  <form onSubmit={onTableReservation}>
                    <div>
                      <h2 className="mb-4 hidden text-xl font-bold md:block">
                        Guest Details
                      </h2>
                      <div className="my-5 grid grid-cols-1 gap-7 md:grid-cols-3">
                        <div className="relative">
                          <input
                            className="mt-1 w-full rounded-md border-0 bg-cultured"
                            type="text"
                            id="guest_name"
                            placeholder="Guest Name"
                            {...register("guest_name", {
                              required: true,
                            })}
                          />
                          {errors.guest_name && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="relative">
                          <input
                            className="mt-1 w-full rounded-md border-0 bg-cultured"
                            type="number"
                            id="guest_number"
                            placeholder="Guest Number"
                            {...register("guest_number", {
                              required: true,
                              maxLength: 10,
                              minLength: 10,
                            })}
                          />
                          {errors.guest_number && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        {/* <div className="relative">
                          <input
                            className="mt-1 w-full rounded-md border-0 bg-cultured"
                            type="email"
                            id="guest_email"
                            placeholder="Guest Email"
                            {...register("guest_email", {
                              required: true,
                            })}
                          />
                          {errors.guest_email && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div> */}

                        {/* <div className="relative">
                          <select
                            placeholder="Guest Gender"
                            className="mt-1 w-full rounded-md border-0 bg-cultured"
                            id="gender"
                            {...register("gender", {
                              required: true,
                            })}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {errors.gender && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div> */}

                        {/* <div className="relative">
                          <select
                            placeholder="Reservation For"
                            className="mt-1 w-full rounded-md border-0 bg-cultured"
                            id="reservation_type"
                            {...register("reservation_type", {
                              required: true,
                            })}
                          >
                            <option value="lunch">Lunch</option>
                            <option value="diner">Diner</option>
                            <option value="birthday_party">
                              Birthday Party
                            </option>
                            <option value="anniversary_party">
                              Anniversary Party
                            </option>
                          </select>
                          {errors.reservation_type && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div> */}
                      </div>
                    </div>

                    <div className="mt-10 rounded bg-cultured py-3 px-2">
                      <h2 className="mb-4 hidden text-xl font-bold md:block">
                        Reservation Details
                      </h2>
                      <div className="my-5 grid grid-cols-1 gap-7 md:grid-cols-3">
                        <div className="relative">
                          <input
                            className="mt-1 w-full rounded-md border-0 bg-white"
                            type="number"
                            id="guests_number"
                            placeholder="Number of Guests"
                            {...register("guests_number", {
                              required: true,
                            })}
                          />
                          {errors.guests_number && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="relative">
                          <input
                            className="mt-1 w-full rounded-md border-0 bg-white"
                            type="datetime-local"
                            min={new Date().toISOString().slice(0, -8)}
                            id="reservation_date_time"
                            placeholder="Reservation Date Time"
                            {...register("reservation_date_time", {
                              required: true,
                            })}
                          />
                          {errors.reservation_date_time && (
                            <p className="absolute right-0 flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="my-5 flex justify-end">
                    <Button onClick={() => checkForTable()}>
                      Check the table available
                    </Button>
                  </div>

                  {!(tableList.length === 0 || undefined) && (
                    <div className="mt-10">
                      <h2 className="mb-4 hidden text-xl font-bold md:block">
                        Table Selection
                      </h2>

                      <div>
                        <div>
                          <ul className="flex items-center justify-start gap-5">
                            {Object.keys(tableTabs).map((list, index) => {
                              return (
                                <li className="relative w-fit" key={index}>
                                  <input
                                    type="radio"
                                    name="answer"
                                    id={list}
                                    value={list}
                                    checked={list === selectedTableType}
                                    className="peer sr-only"
                                    onChange={() =>
                                      onSelectTableCategoryTab(list)
                                    }
                                  />
                                  <label
                                    className="peer-checked:ring-blue flex cursor-pointer  select-none items-center gap-2 rounded border-2 border-gray bg-white px-6 py-4 text-sm font-bold capitalize focus:outline-none peer-checked:border-transparent peer-checked:bg-gold peer-checked:text-black"
                                    htmlFor={list}
                                  >
                                    {list}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <ul className="mt-6 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
                          {tableTabs[selectedTableType]?.map(
                            (list: any, index: any) => {
                              return (
                                <li className="relative" key={index}>
                                  <input
                                    type="checkbox"
                                    name="table"
                                    id={list._id}
                                    value={list.table_name}
                                    checked={selectedTable.some(
                                      (table) => table._id === list._id
                                    )}
                                    className="peer sr-only"
                                    onChange={(e) =>
                                      onSelectionTable(e.target.checked, list)
                                    }
                                  />
                                  <label
                                    className="peer-checked:ring-blue flex cursor-pointer  select-none items-center bg-white focus:outline-none "
                                    htmlFor={list._id}
                                  >
                                    <TableBox
                                      key={index}
                                      icon={false}
                                      tableList={list}
                                      bgColor={
                                        selectedTable.some(
                                          (table) => table._id === list._id
                                        )
                                          ? "bg-green/80"
                                          : "bg-cultured"
                                      }
                                    />
                                  </label>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>

                      {selectedTable.length !== 0 && (
                        <div className="mt-4">
                          <h2 className="font-medium">
                            Selected tables: ({selectedTableType}){" "}
                            {selectedTable.map((list, index) => {
                              return (
                                <span key={index} className="font-bold">
                                  {list.table_name},{" "}
                                </span>
                              );
                            })}{" "}
                          </h2>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex justify-end gap-5">
                  <Button loading={false} onClick={onTableReservation}>
                    {props.reservation === undefined ? "Add" : "Update"}
                  </Button>
                  {props.reservation !== undefined && (
                    <Button
                      onClick={() =>
                        deleteReservationHandler(props.reservation?._id)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  <Button loading={false} onClick={() => closeHandler()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddReservationModel;
