import React from "react";
import { CouponObject } from "types";
import { useForm } from "react-hook-form";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  isCoupon: boolean;
  onCoupon: any;
  selectedCoupon: CouponObject | null;
  onUpdateDetails: any;
  onLoading: boolean;
}

const UpdateCouponModel: React.FC<Props> = ({
  isCoupon,
  onCoupon,
  selectedCoupon,
  onUpdateDetails,
  onLoading,
}) => {
  const onCloseCoupon = onCoupon;
  const onUpdateHandel = onUpdateDetails;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isRupee, setIsRupee] = React.useState<any>(false);

  const onSubmit = handleSubmit((data: any) => {
    onUpdateHandel(data);
  });

  const onIsRupeeHandel = (e: boolean) => {
    setIsRupee(e);
    setValue("isInRupee", `${e}`);
  };

  React.useEffect(() => {
    if (selectedCoupon !== null) {
      setValue("coupon_amount", selectedCoupon?.promoAmount);
      setValue("coupon_attempted", selectedCoupon?.promoAttempt);
      setValue("coupon_name", selectedCoupon?.promoCode);
      setValue("isInRupee", selectedCoupon?.isInRupee);
      setIsRupee(selectedCoupon?.isInRupee);
    }
  }, [selectedCoupon, setValue]);

  return (
    <>
      <Transition appear show={isCoupon} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => onCloseCoupon(!isCoupon)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Update Coupon
                  </Dialog.Title>
                  <Separate dark={true} />

                  <div className="p-5 md:px-10">
                    <form onSubmit={onSubmit}>
                      <div className="grid grid-cols-1 space-y-4 md:grid-cols-2">
                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_name"
                          >
                            Coupon Name
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="text"
                            id="coupon_name"
                            placeholder="Coupon Name"
                            disabled
                            {...register("coupon_name", { required: true })}
                          />
                          {errors.coupon_name && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_attempted"
                          >
                            No of limit
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="number"
                            id="coupon_attempted"
                            placeholder="Coupon Attempted"
                            {...register("coupon_attempted", {
                              required: true,
                            })}
                          />
                          {errors.coupon_attempted && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_amount"
                          >
                            Discount Amount
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="number"
                            id="coupon_amount"
                            placeholder="Coupon Amount"
                            disabled
                            {...register("coupon_amount", { required: true })}
                          />
                          {errors.coupon_amount && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="flex md:mx-5">
                            <div className="mr-5 grid">
                              <label
                                className="text-md font-bold"
                                htmlFor="coupon_rupee"
                              >
                                ₹
                              </label>
                              <input
                                className="mt-2 h-9 w-9 rounded-md border-0 bg-cultured"
                                type="radio"
                                id="coupon_rupee"
                                placeholder="Coupon ₹"
                                checked={isRupee === true ? true : false}
                                value={isRupee === true ? "true" : "false"}
                                onChange={(e) =>
                                  onIsRupeeHandel(e.target.checked)
                                }
                                // {...register("isInRupee", { required: true })}
                              />
                            </div>
                            <div className="grid">
                              <label
                                className="text-md font-bold"
                                htmlFor="coupon_per"
                              >
                                %
                              </label>
                              <input
                                className="mt-2 h-9 w-9 rounded-md border-0 bg-cultured"
                                type="radio"
                                id="coupon_per"
                                placeholder="Coupon %"
                                checked={isRupee === false ? true : false}
                                value={isRupee === false ? "true" : "false"}
                                onChange={(e) =>
                                  onIsRupeeHandel(!e.target.checked)
                                }
                                // {...register("isInRupee", { required: true })}
                              />
                            </div>
                          </div>
                          {errors.isInRupee && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="mx-auto mt-10 grid max-w-xs grid-cols-2">
                    <Button
                      variant="outlined"
                      onClick={() => onCloseCoupon(!isCoupon)}
                    >
                      Cancel
                    </Button>
                    <Button loading={onLoading} onClick={onSubmit}>
                      Apply
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdateCouponModel;
