import React from "react";
import axios from "axios";
import moment from "moment";
import {
  ReservedTable,
  ShpoLocalstorage,
  StoreTableList,
  WatingWalkin,
} from "types";
import API from "config/Api";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Button, Table } from "components/ui";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import AddWaitingModel from "components/shops/reservation/AddWaitingModel";
import AddReservationModel from "components/shops/reservation/AddReservationModel";

interface CheckTableAvalibate {
  bookingDate: string;
  numberOfGuest: string;
}

const TableReservation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableList, setTableList] = React.useState<StoreTableList | []>([]);
  const [isTableReservation, setIsTableReservation] = React.useState(false);
  const [isWaitingReservation, setIsWaitingReservation] = React.useState(false);
  const [activeList, setActiveList] = React.useState("walkin");
  const [reservedTableList, setReservedTableList] = React.useState<
    ReservedTable[]
  >([]);
  const [walkinTableList, setWalkinTableList] = React.useState<WatingWalkin[]>(
    []
  );
  const [selectedViewReservation, setSelectedViewReservation] =
    React.useState<ReservedTable>();
  const [selectedViewWaiting, setSelectedViewWaiting] =
    React.useState<WatingWalkin>();

  //FETCH TABLE LIST WITH SELECTED DATE AND NUMBER OF GUEST
  const fetchTableList = (data: CheckTableAvalibate) => {
    setIsLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `tableListForReservation`;
    const body = {
      storeId: authData?._id,
      numberOfGuest: data.numberOfGuest,
      bookingDate: new Date(data.bookingDate),
    };
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setTableList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //FETCH TABLE LIST WITH SELECTED DATE AND NUMBER OF GUEST END

  //FETCH RESERVATION LIST
  const fetchReservedTableList = React.useCallback(() => {
    setIsLoading(true);
    //TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    //URL
    const url = API + `table/reservation/${authData?._id}`;
    //API CALL
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setReservedTableList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //FETCH RESERVATION LIST END

  //FETCH WALKIN | WAITING LIST
  const fetchWalkinTableList = React.useCallback(() => {
    setIsLoading(true);
    //TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    //URL
    const url = API + `table/waiting/list/${authData?._id}`;
    //API CALL
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setWalkinTableList(
            response?.data?.data?.sort((a: any, b: any) =>
              a?.priority > b?.priority ? 1 : -1
            )
          );
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //FETCH WALKIN | WAITING LIST END

  //RESERVED TABLE LIST
  const reservedList = React.useMemo(
    () => reservedTableList,
    [reservedTableList]
  );
  const reservedColumns = React.useMemo(
    () => [
      {
        Header: "Reservation Name",
        accessor: "guestName",
      },
      {
        Header: "Reservation Number",
        accessor: "guestNumber",
      },
      {
        Header: "Table No",
        accessor: "tableNumber",
        Cell: ({ row }: any) => (
          <>
            {row.original.selectTable.map((list: any, index: number) => {
              return (
                <div key={index} className="flex items-center gap-1 uppercase">
                  <span className="font-bold">
                    {row.original.selectTable[0].tableId.table_location}
                  </span>
                  <span className="font-medium">({list.tableName})</span>
                </div>
              );
            })}{" "}
          </>
        ),
      },
      {
        Header: "Guests",
        accessor: "numberOfGuest",
      },
      {
        Header: "Select Date",
        accessor: "bookingDate",
        Cell: ({ row }: any) => (
          <>{moment(row.original.bookingDate).format("D MMMM YYYY")}</>
        ),
      },
      {
        Header: "Select Time",
        accessor: "bookingTime",
        Cell: ({ row }: any) => (
          <>{moment(row.original.bookingDate).format("hh:mm A")}</>
        ),
      },
      // {
      //   Header: "Type",
      //   accessor: "reservation_type",
      // },
      {
        Header: "View",
        accessor: "orders",
        Cell: ({ row }) => (
          <div
            className="w-fit cursor-pointer rounded bg-gold px-3 py-2 text-sm text-raisin-black"
            onClick={() => {
              setSelectedViewReservation(row.original);
              setIsTableReservation(true);
            }}
          >
            View Reservation
          </div>
        ),
      },
    ],
    []
  );
  //RESERVED TABLE LIST END

  //WALKIN | WAITING LIST
  const walkinList = React.useMemo(() => walkinTableList, [walkinTableList]);
  const walkinColumns = React.useMemo(
    () => [
      {
        Header: "Reservation Name",
        accessor: "guestName",
      },
      {
        Header: "Reservation Number",
        accessor: "guestNumber",
      },
      {
        Header: "Table No",
        accessor: "allocateTable",
        Cell: ({ row }: any) => (
          <>
            {row?.original?.allocateTable?.map((list: any, index: number) => {
              return (
                <div key={index} className="flex items-center gap-1 uppercase">
                  <span className="font-bold">
                    {row?.original?.allocateTable[0]?.tableId?.table_location}
                  </span>
                  <span className="font-medium">({list?.tableName})</span>
                </div>
              );
            })}{" "}
          </>
        ),
      },
      {
        Header: "Guests",
        accessor: "numberOfGuest",
      },
      // {
      //   Header: "Select Date",
      //   accessor: "bookingDate",
      //   Cell: ({ row }: any) => (
      //     <>{moment(row.original.bookingDate).format("D MMMM YYYY")}</>
      //   ),
      // },
      // {
      //   Header: "Select Time",
      //   accessor: "bookingTime",
      //   Cell: ({ row }: any) => (
      //     <>{moment(row.original.bookingDate).format("hh:mm A")}</>
      //   ),
      // },
      // {
      //   Header: "Type",
      //   accessor: "reservation_type",
      // },
      {
        Header: "View",
        accessor: "walkin",
        Cell: ({ row }) => (
          <div
            className="w-fit cursor-pointer rounded bg-gold px-3 py-2 text-sm text-raisin-black"
            onClick={() => {
              setSelectedViewWaiting(row.original);
              setIsWaitingReservation(true);
            }}
          >
            View Walkin
          </div>
        ),
      },
    ],
    []
  );
  //WALKIN | WAITING LIST END

  const onCheckTableAvalibate = (data: CheckTableAvalibate) => {
    fetchTableList(data);
  };

  //CLOSE FUNCTION FOR RESERVATION
  const onCloseReservation = () => {
    setIsTableReservation(false);
    setSelectedViewReservation(undefined);
    setTableList([]);
  };
  //CLOSE FUNCTION FOR RESERVATION END

  //CREATE | UPDATE FUNCTION FOR RESERVATION
  const onCreateReservation = (data: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    //CREATE SELECTED TABLE BODY
    const selectedTableArr = [];
    for (let i = 0; i < data?.selectedTable?.length; i++) {
      const element = data.selectedTable[i];
      const selectTable = {
        tableId: element._id,
        tableName: element.table_name,
      };
      selectedTableArr.push(selectTable);
    }
    //CREATE SELECTED TABLE BODY END

    //TABLE RESERVATION API BODY
    const body = {
      selectTable: selectedTableArr,
      storeId: authData?._id,
      guestName: data?.guest_name,
      guestNumber: data?.guest_number,
      guestEmail: data?.guest_email,
      reservation_type: data?.reservation_type,
      numberOfGuest: data?.guests_number,
      bookingDate: data?.reservation_date_time,
    };
    //TABLE RESERVATION API BODY END

    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //CHECK FOR RESERVATION UPDATE OR CREATE
    if (data._id !== undefined) {
      //UPDATE RESERVATION DETAILS
      //URL
      const url = API + `table/reservation/update/${data._id}`;

      axios
        .patch(url, body, config)
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success(response?.data?.message);
            fetchReservedTableList();
            onCloseReservation();
          } else {
            toast.warn(response.data.message);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    } else {
      //CREATE RESERVATION WITH DETAILS
      //URL
      const url = API + `table/reservation/create`;

      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success(response?.data?.message);
            fetchReservedTableList();
            onCloseReservation();
          } else {
            toast.warn(response.data.message);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    }
  };
  //CREATE | UPDATE FUNCTION FOR RESERVATION END

  //DELETE FUNCTION FOR RESERVATION
  const onDeleteReservation = (id: string) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //URL
    const url = API + `table/reservation/delete/${id}`;

    axios
      .delete(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);
          setActiveList("reservation");
          fetchReservedTableList();
          onCloseReservation();
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };
  //DELETE FUNCTION FOR RESERVATION END

  //CLOSE FUNCTION FOR WALKIN | WAITING
  const onCloseWaiting = () => {
    setIsWaitingReservation(false);
    setSelectedViewWaiting(undefined);
  };
  //CLOSE FUNCTION FOR WALKIN | WAITING END

  //CREATE | UPDATE FUNCTION FOR WALKIN | WAITING
  const onCreateWaitingQueue = (data: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    //TABLE WAITING / WALKIN API BODY
    const body = {
      storeId: authData?._id,
      guestName: data?.guest_name,
      guestNumber: data?.guest_number,
      guestEmail: data?.guest_email,
      reservation_type: data?.reservation_type,
      numberOfGuest: data?.guests_number,
      table_location: data?.table_location,
    } as any;
    //TABLE WAITING / WALKIN API BODY END

    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //CHECK FOR UPDATE OR CREATE
    if (data._id !== undefined) {
      //UPDATE WALKIN | WAITING
      body.allocateTable = data.allocateTable;

      //URL
      const url = API + `table/waiting/update/${data._id}`;

      axios
        .patch(url, body, config)
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success(response?.data?.message);
            onCloseWaiting();
            setActiveList("walkin");
            fetchWalkinTableList();
          } else {
            console.log(response);

            toast.warn(response.data.message);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    } else {
      //CREATE WALKIN | WAITING

      //URL
      const url = API + `table/waiting/create`;

      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success(response?.data?.message);
            onCloseWaiting();
            setActiveList("walkin");
            fetchWalkinTableList();
          } else {
            toast.warn(response.data.message);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    }
  };
  //CREATE | UPDATE FUNCTION FOR WALKIN | WAITING END

  //DELETE FUNCTION FOR WALKIN | WAITING
  const onDeleteWaiting = (id: string) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    //CONFIG AND TOKEN HEADER
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //URL
    const url = API + `table/waiting/delete/${id}`;

    axios
      .delete(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);
          onCloseWaiting();
          setActiveList("walkin");
          fetchWalkinTableList();
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };
  //DELETE FUNCTION FOR WALKIN | WAITING END

  React.useEffect(() => {
    fetchReservedTableList();
    fetchWalkinTableList();
  }, [fetchReservedTableList, fetchWalkinTableList]);

  return (
    <>
      <DashboardHeader title={"Table's Reservation / Walkin"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-4 hidden text-xl font-bold md:block">
          Table's Reservation / Walkin
        </h2>

        <div className="grid grid-cols-1 items-center justify-between gap-5 rounded bg-cultured px-5 py-1 md:grid-cols-2">
          <div className="flex gap-6">
            <h2
              className={classNames(
                activeList === "walkin"
                  ? "cursor-pointer border-b-2 border-gold font-medium"
                  : "cursor-pointer"
              )}
              onClick={() => setActiveList("walkin")}
            >
              Walkin Waiting List
            </h2>
            <h2
              className={classNames(
                activeList === "reservation"
                  ? "cursor-pointer border-b-2 border-gold font-medium"
                  : "cursor-pointer"
              )}
              onClick={() => setActiveList("reservation")}
            >
              Reservation List
            </h2>
            <h2
              className={classNames(
                activeList === "live"
                  ? "cursor-pointer border-b-2 border-gold font-medium"
                  : "cursor-pointer"
              )}
              onClick={() => setActiveList("live")}
            >
              Live Request
            </h2>
          </div>
          <div className="flex justify-end gap-2">
            <Button onClick={() => setIsWaitingReservation(true)}>
              Add Walkin / Waiting
            </Button>
            <Button onClick={() => setIsTableReservation(true)}>
              Add Reservation
            </Button>
          </div>
        </div>

        <div className="mt-5">
          {activeList === "walkin" && (
            <>
              <h2 className="mb-2 text-lg font-medium">
                Table's Walkin / Waiting
              </h2>
              <div className="rounded-xl bg-cultured p-2 px-4">
                <Table
                  columns={walkinColumns}
                  data={walkinList}
                  loading={isLoading}
                />
              </div>
            </>
          )}
          {activeList === "reservation" && (
            <>
              <h2 className="mb-2 text-lg font-medium">Table's Reservation</h2>
              <div className="rounded-xl bg-cultured p-2 px-4">
                <Table
                  columns={reservedColumns}
                  data={reservedList}
                  loading={isLoading}
                />
              </div>
            </>
          )}
          {activeList === "live" && (
            <>
              <h2 className="mb-2 text-lg font-medium">Live request</h2>
              <div className="rounded-xl bg-cultured p-2 px-4">
                <h2 className="text-center font-bebas text-xl uppercase tracking-[.25em]">
                  Comming soon.....
                </h2>
              </div>
            </>
          )}
        </div>
      </section>

      <AddReservationModel
        onOpen={isTableReservation}
        onClose={() => onCloseReservation()}
        tableList={tableList}
        tableAvalibate={onCheckTableAvalibate}
        createReservation={onCreateReservation}
        reservation={selectedViewReservation}
        deleteReservation={onDeleteReservation}
      />

      <AddWaitingModel
        onOpen={isWaitingReservation}
        onClose={() => onCloseWaiting()}
        createWaiting={onCreateWaitingQueue}
        walkin={selectedViewWaiting}
        deleteWaiting={onDeleteWaiting}
      />
    </>
  );
};

export default TableReservation;
