import _ from "lodash";
import axios from "axios";
import React from "react";
import API from "config/Api";
import { toast } from "react-toastify";
import { socket } from "routes/ProtectedRoute";
import TableBox from "components/shops/table/TableBox";
import { Dialog, Transition } from "@headlessui/react";
import { ShpoLocalstorage, StoreTableList } from "types";
import WaiterCall from "components/shops/table/WaiterCall";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

const TableManagement = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableList, setTableList] = React.useState<StoreTableList | []>([]);
  const [isDeleteModal, setIsDeleteModal] = React.useState<boolean>(false);
  const [modelOpenName, setModelOpenName] = React.useState<string>("Delete");

  //FUNCTION FOR PLAY SOUND WHEN NEW ORDER RECEVED
  const playVideo = () => {
    const audio = new Audio("/assets/shop/notification.mp3");
    audio.loop = false;
    audio.play();
  };

  const fetchTableList = React.useCallback(() => {
    setIsLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `table/list/${authData?._id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setTableList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const tablesDisplay = _.groupBy(
    tableList?.sort((a: any, b: any) => (a?.priority > b?.priority ? 1 : -1)),
    "table_location"
  );

  // const onCloseTableUpdateModel = (status: boolean) => {
  //   setIsTableUpdateModel(status);
  // };

  const onUpdateTableList = () => {
    setIsDeleteModal(true);
    setModelOpenName("Update");
  };

  const onDeleteTableList = () => {
    setIsDeleteModal(true);
    setModelOpenName("Delete");
  };

  const onWaiterClear = (id: string) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const body = {
      tableId: id,
    };
    const url = API + `waiter/clear`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchTableList();
          toast.success(response?.data?.message);
        } else {
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  // const onUpdateTable = (details: StoreTableOblect) => {
  //   setTableUpdateLoading(true);
  //   const authData = JSON.parse(localStorage.getItem("jd-u")!);
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${authData?.token}`,
  //     },
  //   };
  //   const body = {
  //     table_name: details.table_name,
  //   };
  //   const url = API + `table/update/${details?._id}`;
  //   axios
  //     .patch(url, body, config)
  //     .then((response) => {
  //       if (response?.data?.success === true) {
  //         fetchTableList();
  //         onCloseTableUpdateModel(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error here", err);
  //     })
  //     .finally(() => {
  //       setTableUpdateLoading(false);
  //     });
  // };

  const onAvailabilityTable = (id: any, status: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const body = {
      availability: !status,
    };
    const url = API + `table/availability/${id}`;
    axios
      .patch(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchTableList();
          toast.success(response?.data?.message);
        } else {
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  React.useEffect(() => {
    fetchTableList();
  }, [fetchTableList]);

  //GET TABLE ALERT AND NEW LIST OF TABLE FROM SOCKET
  React.useEffect(() => {
    socket?.on("tableAlert", (data) => {
      if (data) {
        setTableList(data?.tableList);
        playVideo();
        return;
      }
    });
  });

  return (
    <>
      <DashboardHeader title={"Table Management"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-4 hidden text-xl font-bold md:block">
          Table's Management
        </h2>
        <WaiterCall tableList={tableList} onNoteClear={onWaiterClear} />
        {Object.keys(tablesDisplay)?.map((title, index) => {
          return (
            <div key={index}>
              <h3 className="my-4 mt-7 text-xl font-bold uppercase">
                {title}{" "}
                <span className="text-sm">
                  ({tablesDisplay[title]?.length})
                </span>
              </h3>
              <ul className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {tablesDisplay[title]?.map((list, index2) => {
                  return (
                    <li key={index2}>
                      <TableBox
                        key={index}
                        icon={true}
                        onLoading={isLoading}
                        tableList={list}
                        onUpdate={onUpdateTableList}
                        onActive={onAvailabilityTable}
                        onDelete={onDeleteTableList}
                        onWaiter={onWaiterClear}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
        {tableList === null && (
          <div className="w-fit rounded bg-cultured p-2">
            <p>Informed your administrator for adding new tables.</p>
          </div>
        )}
      </section>

      {/* <UpdateTable
        isTable={isTableUpdateModel}
        selectedTable={selectedTable}
        onLoading={tableUpdateLoading}
        onUpdateDetails={onUpdateTable}
        onTable={onCloseTableUpdateModel}
      /> */}

      <Transition appear show={isDeleteModal} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsDeleteModal(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 text-lg font-medium leading-6"
                  >
                    Confirm {modelOpenName}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 text-sm">
                      You don't have permission to {modelOpenName} this table.
                    </p>
                  </div>

                  <div className="mt-4 flex space-x-3">
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={() => setIsDeleteModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TableManagement;
