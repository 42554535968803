import { Dialog, Transition } from "@headlessui/react";
import { Button, Separate } from "components/ui";
import React from "react";
import CategorieBox from "./CategorieBox";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { HandIcon } from "@heroicons/react/outline";

interface Props {
  onOpen: any;
  onClose: any;
  onUpdate: any;
  categoriesList: any[];
}

const UpdatePriority: React.FC<Props> = ({
  onOpen,
  onClose,
  categoriesList,
  onUpdate,
}) => {
  const closeHandler = onClose;
  const updateHandler = onUpdate;
  const [isMobile, setIsMobile] = React.useState(false);
  const [items, setItems] = React.useState({
    left: [] as any[],
  });

  function onChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) {
    const result = swap(items["left"], sourceIndex, targetIndex);
    //SET PRIORITY FOR CATEGORIES
    for (let i = 0; i < result?.length; i++) {
      const element = result[i];
      element.priority = i + 1;
    }
    setItems({
      ...items,
      [sourceId]: result,
    });
  }

  const onSubmit = () => {
    updateHandler(items.left);
  };

  React.useEffect(() => {
    setItems({
      left: categoriesList || [],
    });
    const width = window.innerWidth;
    const breakpoint = 620;
    if (width < breakpoint) {
      setIsMobile(true);
    }
  }, [categoriesList]);

  return (
    <Transition appear show={onOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeHandler}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                >
                  Categories Priority Update.
                </Dialog.Title>
                <Separate dark={true} />

                <div className="my-10">
                  <GridContextProvider onChange={onChange}>
                    <GridDropZone
                      className="flex h-96 overflow-y-auto"
                      id="left"
                      boxesPerRow={isMobile === false ? 4 : 1}
                      rowHeight={110}
                    >
                      {items?.left
                        // ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
                        .map((list: any, index: number) => (
                          <GridItem
                            key={index}
                            className="gridbox"
                            style={{
                              width: isMobile === false ? "120px" : "100px",
                            }}
                          >
                            <CategorieBox key={index} items={list}>
                              <div className="flex justify-center text-philippine-gray">
                                <HandIcon className="mr-3 h-5 w-5 cursor-move" />
                              </div>
                            </CategorieBox>
                          </GridItem>
                        ))}
                    </GridDropZone>
                  </GridContextProvider>
                </div>

                <div className="mx-auto mt-10 grid max-w-xs grid-cols-2">
                  <Button variant="outlined" onClick={closeHandler}>
                    Cancel
                  </Button>
                  <Button onClick={onSubmit}>Submit</Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdatePriority;
