import React from "react";
import {
  EyeIcon,
  EyeOffIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Coupons } from "types";

interface Props {
  onLoading: boolean;
  couponsList: Coupons | [];
  onDelete?: any;
  onUpdate?: any;
  onActive?: any;
  icon: boolean;
}

const tempImage = [
  "/assets/shop/coupon/1.png",
  "/assets/shop/coupon/2.png",
  "/assets/shop/coupon/3.png",
  "/assets/shop/coupon/4.png",
  "/assets/shop/coupon/5.png",
  "/assets/shop/coupon/6.png",
  "/assets/shop/coupon/7.png",
  "/assets/shop/coupon/8.png",
  "/assets/shop/coupon/9.png",
];

const CouponBox: React.FC<Props> = ({
  onLoading,
  couponsList,
  onDelete,
  onUpdate,
  onActive,
  icon,
}) => {
  const activeHandler = onActive;
  const updateHandler = onUpdate;
  const deleteHandler = onDelete;

  if (onLoading) {
    return (
      <>
        <div className="w-full animate-pulse">
          <div className="h-full w-full select-none rounded-2xl bg-cultured p-5">
            <div className="grid grid-cols-2 space-x-5 border-b border-gray/20 pb-2">
              <div className="space-y-4">
                <div className="h-4 w-32 rounded bg-black/20" />
                <div className="h-7 w-full rounded bg-black/20" />
                <div className="h-4 w-32 rounded bg-black/20" />
              </div>
              <div className="mx-auto h-32 w-32 rounded-md bg-black/20" />
            </div>
            <div className="flex justify-between px-5 pt-3">
              <div className="h-5 w-5  rounded bg-black/20" />
              <div className="h-5 w-5  rounded bg-black/20" />
              <div className="h-5 w-5  rounded bg-black/20" />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {couponsList.map((list, index) => {
          return (
            <div
              className="h-fit w-full select-none rounded-2xl bg-cultured p-5"
              key={index}
            >
              <div className="grid grid-cols-2 pb-2">
                <div>
                  <h2 className="mb-1 text-xl font-medium uppercase">
                    {list?.promoCode}
                  </h2>
                  <h2 className="font-bebas text-6xl uppercase text-gray">
                    {list?.promoAmount}
                    {list?.isInRupee ? "₹Off" : "%Off"}
                  </h2>
                  <h2 className="text-lg font-medium uppercase">
                    Used {list?.promoAttempt}/{list?.individualPromoAttempt}
                  </h2>
                </div>
                <div>
                  <img
                    className="mx-auto h-36 w-36 object-cover"
                    src={`${
                      tempImage[Math.floor(Math.random() * tempImage.length)]
                    }`}
                    alt={`coupon_${index}`}
                  />
                </div>
              </div>
              {icon && (
                <div className="flex justify-between px-5 pt-3 text-philippine-gray">
                  {list?.isBlocked ? (
                    <>
                      <div className="group relative">
                        <EyeIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            activeHandler(list?._id, list?.isBlocked)
                          }
                        />
                        <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Active
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="group relative">
                        <EyeOffIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            activeHandler(list?._id, list?.isBlocked)
                          }
                        />
                        <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Inactive
                        </p>
                      </div>
                    </>
                  )}
                  <div className="group relative">
                    <PencilAltIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={() => updateHandler(list)}
                    />
                    <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                      Update
                    </p>
                  </div>
                  <div className="group relative">
                    <TrashIcon
                      className="mr-3 h-5 w-5 cursor-pointer	"
                      onClick={() => deleteHandler(list?._id)}
                    />
                    <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                      Delete
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
};

export default CouponBox;
