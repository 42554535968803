import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { Button } from "components/ui";
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ShopCategorie, ShpoLocalstorage } from "types";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import CategorieBox from "components/shops/categorie/CategorieBox";
import InputCategorie from "components/shops/categorie/InputCategorie";
import UpdatePriority from "components/shops/categorie/UpdatePriority";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

interface Props {}

const Categorie: React.FC<Props> = () => {
  const [isAddCategorie, setIsAddCategorie] = React.useState<boolean>(false);
  const [categorieName, setCategorieName] = React.useState("");
  const [updateCategorie, setUpdateCategorie] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [deleteCategorieId, setDeleteCategorieId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [categorieList, setCategorieList] = React.useState<ShopCategorie | []>(
    []
  );
  const [isPriority, setIsPriority] = React.useState(false);

  const fetchShopCategorieList = React.useCallback(() => {
    setIsLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `category/getallbystore/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setCategorieList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onShowInput = (status: boolean) => {
    if (isAddCategorie) {
      setIsAddCategorie(status);
      setCategorieName("");
    } else if (!isAddCategorie) {
      setIsAddCategorie(status);
    }
  };

  const onUpdateShowInput = (name: any, id: any) => {
    if (isAddCategorie) {
      setIsAddCategorie(false);
      setCategorieName("");
      setUpdateId("");
    } else if (!isAddCategorie) {
      setUpdateId(id);
      setCategorieName(name);
      setIsAddCategorie(true);
    }
    setUpdateCategorie(!updateCategorie);
  };

  const onAddCategorie = () => {
    if (categorieName === "") {
      toast.warn("Category Name field is necessary");
    } else {
      const authData = JSON.parse(
        localStorage.getItem("jode_shop")!
      ) as ShpoLocalstorage;
      const body = {
        storeId: authData?.id,
        name: categorieName,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${authData?.accessToken}`,
        },
      };
      const url = API + `category`;
      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success === true) {
            fetchShopCategorieList();
            onShowInput(false);
            toast.success(`Category Created Successfully.`);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        });
    }
  };

  const onUpdateCategorie = () => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const body = {
      name: categorieName,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `category/${updateId}`;
    axios
      .patch(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchShopCategorieList();
          onShowInput(false);
          toast.success(`Category Updated Successfully.`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onBlockCategorie = (id?: String, status?: Boolean) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const body = {
      is_active: !status,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `blockCategory/${id}`;
    axios
      .patch(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchShopCategorieList();
          toast.success(
            `Category ${!status ? "Unblock" : "Block"} Successfully.`
          );
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  function openDeleteModal(id: any) {
    setIsDeleteModal(true);
    setDeleteCategorieId(id);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
  }

  const onDelete = () => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const body = {
      id: deleteCategorieId,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `categoryDelete`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchShopCategorieList();
          setDeleteCategorieId("");
          closeDeleteModal();
          toast.success(`Categorie Delete Successfully.`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onUpdatePriority = (item: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `category/priorityUpdate`;
    axios
      .post(url, item, config)
      .then((response) => {
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);
          setIsPriority(false);
          fetchShopCategorieList();
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  React.useEffect(() => {
    fetchShopCategorieList();
  }, [fetchShopCategorieList]);

  if (isLoading) {
    return (
      <>
        <div className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
          <h2 className="mb-3 hidden text-xl font-bold md:block">Category</h2>
          <div className="h-24 w-40 rounded-xl bg-cultured p-2 md:w-48">
            <div className="animate-pulse">
              <div className="mb-1 h-14 w-full rounded-2xl bg-black/20"></div>
              <div className="flex justify-center">
                <div className="mr-3 h-5 w-5 rounded-md bg-black/20"></div>
                <div className="mr-3 h-5 w-5 rounded-md bg-black/20"></div>
                <div className="mr-3 h-5 w-5 rounded-md bg-black/20"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <DashboardHeader title={"Categorys"} />
        <div className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
          <h2 className="mb-3 hidden text-xl font-bold md:block">Categorys</h2>
          <div className="mb-5 flex justify-end">
            <Button onClick={() => setIsPriority(true)}>Update Priority</Button>
          </div>
          <div className="mb-10 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            {isAddCategorie && (
              <InputCategorie value={categorieName} onChange={setCategorieName}>
                <div className="flex justify-center text-philippine-gray">
                  {updateCategorie ? (
                    <CheckIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={onUpdateCategorie}
                    />
                  ) : (
                    <CheckIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={onAddCategorie}
                    />
                  )}
                  <XIcon
                    className="mr-3 h-5 w-5 cursor-pointer"
                    onClick={() => onShowInput(!isAddCategorie)}
                  />
                </div>
              </InputCategorie>
            )}
            {categorieList
              ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
              ?.map((items, index) => {
                return (
                  <CategorieBox key={index} items={items}>
                    {/* <div className="flex justify-center text-philippine-gray">
                    {items.is_active ? (
                      <EyeIcon
                        className="mr-3 h-5 w-5 cursor-pointer"
                        onClick={() =>
                          onBlockCategorie(items?._id, items?.is_active)
                        }
                      />
                    ) : (
                      <EyeOffIcon
                        className="mr-3 h-5 w-5 cursor-pointer"
                        onClick={() =>
                          onBlockCategorie(items?._id, items?.is_active)
                        }
                      />
                    )}
                    <PencilAltIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={() => onUpdateShowInput(items?.name, items?._id)}
                    />
                    <TrashIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={() => openDeleteModal(items?._id)}
                    />
                  </div> */}
                  </CategorieBox>
                );
              })}
          </div>
        </div>

        <Transition appear show={isDeleteModal} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeDeleteModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-gray-900 text-lg font-medium leading-6"
                    >
                      Confirm Delete
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-gray-500 text-sm">
                        Are you sure you want to Delete?
                      </p>
                    </div>

                    <div className="mt-4 flex space-x-3">
                      <button
                        type="button"
                        className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                        onClick={onDelete}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                        onClick={closeDeleteModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        <UpdatePriority
          onOpen={isPriority}
          onClose={() => setIsPriority(false)}
          categoriesList={categorieList}
          onUpdate={onUpdatePriority}
        />
      </>
    );
  }
};

export default Categorie;
