import * as React from "react";

interface CtxState {
  signInView: boolean;
  setSignInView: React.Dispatch<React.SetStateAction<boolean>>;
  orderCartLocalCount: number;
  setOrderCartLocalCount: React.Dispatch<React.SetStateAction<number>>;
  isStaffOrderView: boolean;
  setIsStaffOrderView: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: CtxState = {
  signInView: true,
  setSignInView: () => {},
  orderCartLocalCount: 0,
  setOrderCartLocalCount: () => {},
  isStaffOrderView: false,
  setIsStaffOrderView: () => {},
};

export const AppCtx = React.createContext<CtxState>(initialState);

const CtxProvider: React.FC = ({ children }) => {
  const [signInView, setSignInView] = React.useState<boolean>(true);
  const [orderCartLocalCount, setOrderCartLocalCount] =
    React.useState<number>(0);
  //STAFF PORTAL
  const [isStaffOrderView, setIsStaffOrderView] =
    React.useState<boolean>(false);

  return (
    <AppCtx.Provider
      value={{
        signInView,
        setSignInView,
        orderCartLocalCount,
        setOrderCartLocalCount,
        isStaffOrderView,
        setIsStaffOrderView,
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export default CtxProvider;
