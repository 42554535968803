import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Button, Separate } from "components/ui";
import React from "react";
import { StoreTableOblect } from "types";

type WaiterNoteModelInterface = {
  onClose: any;
  onOpen: boolean;
  onNoteConfirm: any;
  waiterCallTable?: StoreTableOblect;
};
const WaiterNoteModel = (props: WaiterNoteModelInterface) => {
  const closeHandler = props.onClose;
  const orderPlacedHandler = props.onNoteConfirm;
  return (
    <Transition appear show={props.onOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => closeHandler()}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 flex justify-center pb-4 text-xl font-medium leading-6"
                >
                  Waiter note
                </Dialog.Title>
                <Separate dark={true} />
                <div className="p-5 md:px-10">
                  <h2>
                    A call for waiter on table no{" "}
                    {props.waiterCallTable?.table_name} for{" "}
                    {props.waiterCallTable?.waiter.note}.
                  </h2>
                </div>
                <div className="flex justify-center gap-5">
                  <Button loading={false} onClick={() => closeHandler()}>
                    Cancel
                  </Button>
                  <Button
                    loading={false}
                    onClick={() => {
                      orderPlacedHandler(props.waiterCallTable?._id);
                      closeHandler();
                    }}
                  >
                    Update Query
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WaiterNoteModel;
