import React from "react";
import { Outlet } from "react-router-dom";
import { ShopType } from "types";
import Sidebar from "../Sidebar";

interface Props {}

const DashboardLayout: React.FC<Props> = () => {
  return (
    <div>
      <Sidebar />
      <main className="md:pl-28 lg:pl-52">
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
