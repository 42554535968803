import React from "react";
import TableBox from "./TableBox";
import WaiterNoteModel from "./WaiterNoteModel";
import { StoreTableList, StoreTableOblect } from "types";

type WaiterCallInterface = {
  tableList: StoreTableList | [];
  onNoteClear: any
};

const WaiterCall = (props: WaiterCallInterface) => {
  const [isWaiterModel, setIsWaiterModel] = React.useState(false);
  const [waiterCallTable, setWaiterCallTable] =
    React.useState<StoreTableOblect>();

    const waiterNoteHandler = props.onNoteClear;

  const onWaiterNoteModel = (item: StoreTableOblect, status: boolean) => {
    setWaiterCallTable(item);
    setIsWaiterModel(status);
  };

  return (
    <>
      {props.tableList.some((e) => e.waiter.isCalled) && (
        <div>
          <h2 className="my-4 mt-7 text-xl font-bold">Waiter Call List</h2>
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {props.tableList
              .filter((fil) => fil.waiter.isCalled === true)
              .map((item, index) => {
                return (
                  <TableBox
                    key={index}
                    icon={false}
                    tableList={item}
                    onWaiterNote={onWaiterNoteModel}
                  />
                );
              })}
          </div>
        </div>
      )}
      <WaiterNoteModel
        onOpen={isWaiterModel}
        onNoteConfirm={waiterNoteHandler}
        waiterCallTable={waiterCallTable}
        onClose={() => setIsWaiterModel(false)}
      />
    </>
  );
};

export default WaiterCall;
