import React from "react";
import axios from "axios";
import API from "config/Api";
import {
  DownloadIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ShopCategorie, ShopType, ShpoLocalstorage } from "types";
import { Button, HeroIcon, Loading } from "components/ui";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

interface Props {}

const ProductAdd: React.FC<Props> = () => {
  let navigate = useNavigate();
  const [isIndividual, setIsIndividual] = React.useState(true);
  const [productAddons, setProductAddons] = React.useState<any[]>([
    {
      addons_name: "",
      is_multiple: true,
      ingredient: [{ ingredient_name: "", ingredient_price: "" }],
    },
  ]);
  const [categorieLists, setCategorieLists] = React.useState<
    ShopCategorie | []
  >([]);
  const [imgPath, setImgPath] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const [exclePath, setExclePath] = React.useState<any>([]);
  const [isExcelUploadLoading, setIsExcelUploadLoading] = React.useState(false);
  const [selectCategorieInExcel, setSelectCategorieInExcel] =
    React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const formData = new FormData();
  const formData2 = new FormData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //API CALL
  const fetchCategorieLists = React.useCallback(() => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `category/getallbystore/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setCategorieLists(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, []);

  const onCheckIngredient = (data: any[]) => {
    let array_adons = data.filter((addone) => {
      let ingredient = addone.ingredient;
      let isValidAddons = true;
      ingredient = ingredient.filter((ingr: any) => {
        let isValidIngredient = true;
        if (
          ingr.ingredient_name.trim() === "" ||
          ingr.ingredient_price.trim() === ""
        ) {
          isValidIngredient = false;
        }
        return isValidIngredient;
      });
      if (ingredient.length <= 0 || addone.addons_name.trim() === "") {
        isValidAddons = false;
      }
      addone.ingredient = ingredient;
      return isValidAddons;
    });
    return array_adons;
  };

  const onSubmit = handleSubmit((data: any) => {
    setIsLoading(true);
    let array_adons = onCheckIngredient(productAddons);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    imgPath.forEach((file: string | Blob) => {
      formData.append("image", file);
    });

    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
      params: {
        //IT'S CREATE INDIVIDUAL FOLDER FOR SHOP TO ADD PHOTOS.
        directory: authData?.store_name,
      },
    };
    //IMAGE UPLOAD API
    let imgUrl = API + `common/image`;
    axios
      .post(imgUrl, formData, config)
      .then((response) => {
        if (response?.data?.success === true) {
          let image_path = response?.data?.data?.files[0]?.key;
          // PRODUCT UPLOAD API
          const body = {
            store_id: authData?.id,
            category_id: data?.product_categorie,
            name: data?.product_name,
            descriptionInShort: data?.product_short_description,
            description: data?.product_description,
            price: parseInt(data?.product_price),
            // discount: parseInt(data?.discount_price),
            // shippingCharge: parseInt(data?.shipping_price),
            image: [image_path],
            addons: array_adons,
            isVeg: data?.is_veg,
            isInStock: data?.isInStock || true,
          };

          const config = {
            headers: {
              Authorization: `Bearer ${authData?.accessToken}`,
            },
          };
          const url = API + `addproduct`;
          axios
            .post(url, body, config)
            .then((response) => {
              if (response?.data?.success === true) {
                navigate(`/product`);
                toast.success(`Product added Successfully.`);
              }
            })
            .catch((err) => {
              console.log("error here", err);
            });
        } else {
          toast.warning("Image Uploading Fail Try Again.");
        }
      })
      .catch((err) => {
        console.log("error here", err.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const onExcelSubmit = () => {
    setIsExcelUploadLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;

    exclePath.forEach((file: string | Blob) => {
      formData2.append("data", file);
    });
    formData2.append("store_id", `${authData?.id}`);
    formData2.append("category_id", selectCategorieInExcel);

    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //PRODUCT EXCEL UPLOAD API
    let url = API + `addproduct/csv`;
    axios
      .post(url, formData2, config)
      .then((response) => {
        if (response?.data?.success === true) {
          navigate(`/product`);
          toast.success(
            `${response?.data?.data?.product_upload} Product Uploaded By Excel File Successfully.`
          );
        } else {
          toast.warn(
            "Error While Uploading Excel Please User Our Excel Format."
          );
        }
      })
      .catch((err) => {
        console.log(err, "error");
      })
      .finally(() => {
        setIsExcelUploadLoading(false);
      });
  };
  //API CALL END

  const addImage = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...imgPath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setImgPath([...tempImages]);
  };

  const addExcel = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    // reader.onload = function () {
    //   setSelectedExcle(reader.result);
    // };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...exclePath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setExclePath([...tempImages]);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...productAddons];
    list.splice(index, 1);
    setProductAddons(list);
  };

  const handleAddClick = () => {
    setProductAddons([
      ...productAddons,
      {
        addons_name: "",
        is_multiple: true,
        ingredient: [{ ingredient_name: "", ingredient_price: "" }],
      },
    ]);
  };

  const handleInput = (e: EventTarget & HTMLInputElement, index: number) => {
    const { name, value } = e;
    const list = [...productAddons] as any;
    list[index][name] = value;
    setProductAddons(list);
  };

  const handleIngre = (
    e: EventTarget & HTMLInputElement,
    index: number,
    indexOfIndex: number
  ) => {
    const { name, value } = e;
    const list = [...productAddons] as any;
    list[index].ingredient[indexOfIndex][name] = value;
    setProductAddons(list);
  };

  const handleCheckbox = (e: EventTarget & HTMLInputElement, index: number) => {
    const { name, checked } = e;
    const list = [...productAddons] as any;
    list[index][name] = checked;
    setProductAddons(list);
  };

  const onToggle = () => {
    setIsIndividual(!isIndividual);
  };

  const oningredentAdd = (i: number) => {
    const d1 = {
      ingredient_name: "",
      ingredient_price: "",
    };
    productAddons[i].ingredient.push(d1);
    setProductAddons([...productAddons]);
    console.log(productAddons[i].ingredient, "productAddons");
  };

  const onIngredentDelete = (index: number, indexOfIndex: number) => {
    const list = [...productAddons][index].ingredient;
    list.splice(indexOfIndex, 1);
    setProductAddons([...productAddons]);
  };

  const onCancle = () => {
    navigate(`/product`);
  };

  React.useEffect(() => {
    fetchCategorieLists();
  }, [fetchCategorieLists]);

  return (
    <>
      <DashboardHeader title={"Add Product"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">Add Product</h2>
        <div className="rounded-2xl bg-cultured p-2 md:p-5">
          <div className="mt-4 flex">
            <button
              className={`${
                isIndividual
                  ? "mx-5 ml-5 h-10 w-28 rounded-lg border-0 bg-gold font-bold"
                  : "mx-5 ml-5 h-10 w-28 rounded-lg border-2 border-black bg-transparent font-bold text-black"
              }`}
              onClick={() => onToggle()}
            >
              Individual
            </button>
            <button
              className={`${
                isIndividual
                  ? "mx-5 ml-5 h-10 w-28 rounded-lg border-2 border-black bg-transparent font-bold text-black"
                  : "mx-5 h-10 w-28 rounded-lg border-0 bg-gold font-bold"
              }`}
              onClick={() => onToggle()}
            >
              Excel File
            </button>
          </div>

          {isIndividual ? (
            <>
              <form className="space-y-4" onSubmit={onSubmit}>
                <div className="mt-7 lg:flex">
                  <div className="mb-2 grid">
                    {selectedImage.length === 0 ? (
                      <div className="border-sky-500 mb-7 flex h-40 w-32 items-center justify-center rounded-[12px] border-2 border-dashed border-gray">
                        <label
                          htmlFor="product_image"
                          className="cursor-pointer rounded-full bg-philippine-gray"
                        >
                          <HeroIcon
                            className="h-10 w-10 p-2 text-white"
                            icon="PlusIcon"
                          />
                          <input
                            className="hidden"
                            type="file"
                            id="product_image"
                            {...register("product_image", { required: true })}
                            accept="image/*"
                            onChange={addImage}
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="h-40 w-32">
                        <img
                          className="h-full w-full rounded-[12px] object-cover"
                          src={selectedImage}
                          alt=""
                        />
                      </div>
                    )}
                    {errors.product_image && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>

                  <div className="w-full md:ml-5">
                    <div className="md:flex">
                      <div className="mr-7 mb-7 grid w-80">
                        <label
                          className="text-md mb-1 font-bold"
                          htmlFor="product_name"
                        >
                          Product Name
                        </label>
                        <input
                          className="w-11/12 rounded-md border-0 md:w-full"
                          type="text"
                          // name="product_name"
                          id="product_name"
                          placeholder="Product Name"
                          {...register("product_name", { required: true })}
                        />
                        {errors.product_name && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>

                      <div className="mr-7 mb-7 grid w-80">
                        <label
                          className="text-md mb-1 font-bold"
                          htmlFor="product_categorie"
                        >
                          Select Category
                        </label>
                        <select
                          className="w-11/12 rounded-md border-0 md:w-full"
                          // name="product_categorie"
                          id="product_categorie"
                          {...register("product_categorie", { required: true })}
                        >
                          {categorieLists
                            .filter((active) => active.is_active)
                            .map((list, index) => (
                              <option
                                key={index}
                                value={`${list._id}`}
                                selected
                              >
                                {list.name}
                              </option>
                            ))}
                        </select>
                        {errors.product_categorie && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="md:flex">
                      <div className="mb-7 mr-7 grid w-80">
                        <label
                          className="text-md mb-1 font-bold"
                          htmlFor="product_price"
                        >
                          Product Price
                        </label>
                        <input
                          className="w-11/12 rounded-md border-0 md:w-full"
                          type="number"
                          // name="product_price"
                          id="product_price"
                          placeholder="Product Price"
                          {...register("product_price", { required: true })}
                        />
                        {errors.product_price && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                      {/* <div className="mr-7 mb-7 grid w-80">
                        <label
                          className="text-md mb-1 font-bold"
                          htmlFor="discount_price"
                        >
                          Discount Price
                        </label>
                        <input
                          className="w-11/12 rounded-md border-0 md:w-full"
                          type="number"
                          // name="discount_price"
                          id="discount_price"
                          placeholder="Discount Price"
                          {...register("discount_price", { required: true })}
                        />
                        {errors.discount_price && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>

                      <div className="mr-7 mb-7 grid w-80">
                        <label
                          className="text-md mb-1 font-bold"
                          htmlFor="shipping_price"
                        >
                          Shipping Price
                        </label>
                        <input
                          className="w-11/12 rounded-md border-0 md:w-full"
                          type="number"
                          // name="shipping_price"
                          id="shipping_price"
                          placeholder="shipping Price"
                          {...register("shipping_price", { required: true })}
                        />
                        {errors.shipping_price && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div> */}

                      <div className="flex">
                        <div className="mr-7 mb-7 grid w-40">
                          <label className="text-md mb-1 font-bold">
                            Non-Veg?
                          </label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input h-5 w-9 cursor-pointer appearance-none rounded-full bg-gold shadow-sm focus:outline-none"
                              type="checkbox"
                              // role="switch"
                              id="is_veg"
                              defaultChecked={true}
                              {...register("is_veg")}
                            />
                          </div>
                          {errors.is_veg && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                        <div className="mr-7 mb-7 grid w-40">
                          <label className="text-md mb-1 font-bold">
                            Stock Availability
                          </label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input h-5 w-9 cursor-pointer appearance-none rounded-full bg-gold shadow-sm focus:outline-none"
                              type="checkbox"
                              // role="switch"
                              id="isInStock"
                              defaultChecked={true}
                              {...register("isInStock")}
                            />
                          </div>
                          {errors.isInStock && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:flex md:w-10/12">
                  <div className="mt-7 grid md:mr-7 md:w-2/4">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="product_description"
                    >
                      Description
                    </label>
                    <textarea
                      className="h-28 w-full rounded-md border-0"
                      // name="product_description"
                      id="product_description"
                      placeholder="Description"
                      {...register("product_description", { required: true })}
                    ></textarea>
                    {errors.product_description && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>

                  <div className="mt-7 grid md:w-2/4">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="product_short_description"
                    >
                      Short Description
                    </label>
                    <textarea
                      className="h-28 w-full rounded-md border-0"
                      // name="product_short_description"
                      id="product_short_description"
                      placeholder="Short Description"
                      {...register("product_short_description", {
                        required: true,
                      })}
                    ></textarea>
                    {errors.product_short_description && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>
                </div>
              </form>

              {productAddons.length > 0 &&
                productAddons.map((x, i) => {
                  return (
                    <div
                      className="mt-7 w-full rounded-xl bg-white p-4 md:w-10/12"
                      key={i}
                    >
                      <div className="mb-3 flex justify-between">
                        <h2 className="text-md mb-1 font-bold">Add Ons</h2>
                        <div className="flex ">
                          {productAddons.length !== 1 && (
                            <div
                              className="mr-7 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <TrashIcon className="h-4 w-4 text-gray" />
                            </div>
                          )}
                          {productAddons.length - 1 === i && (
                            <div
                              className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                              onClick={handleAddClick}
                            >
                              <PlusIcon className="h-4 w-4 text-gray" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mr-7 w-full md:w-64">
                          <input
                            className="w-full rounded-md border-0 bg-cultured"
                            type="text"
                            name="addons_name"
                            placeholder="Add Ons Name"
                            value={x.addons_name}
                            onChange={(e) => handleInput(e.target, i)}
                          />
                        </div>
                        <div className="flex items-center">
                          <input
                            className="mr-2 border-0 bg-cultured"
                            type="checkbox"
                            name="is_multiple"
                            checked={x.is_multiple}
                            onChange={(e) => handleCheckbox(e.target, i)}
                          />
                          <label
                            className="text-sm text-gray"
                            htmlFor="is_multipel"
                          >
                            Multiple
                          </label>
                        </div>
                      </div>
                      {x.ingredient.map((a: any, b: number) => {
                        return (
                          <div className="mt-7 flex items-center" key={b}>
                            <div className="mr-7 w-48 md:w-64">
                              <input
                                className="w-full rounded-md border-0 bg-cultured"
                                type="text"
                                name="ingredient_name"
                                placeholder="Name"
                                value={a.ingredient_name}
                                onChange={(e) => handleIngre(e.target, i, b)}
                              />
                            </div>
                            <div className="mr-7 w-48 md:w-64">
                              <input
                                className="w-full rounded-md border-0 bg-cultured"
                                type="Number"
                                name="ingredient_price"
                                placeholder="Price"
                                value={a.ingredient_price}
                                onChange={(e) => handleIngre(e.target, i, b)}
                              />
                            </div>
                            <div
                              className="mr-7 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                              onClick={() => oningredentAdd(i)}
                            >
                              <PlusIcon className="h-4 w-4 text-gray" />
                            </div>
                            <div
                              className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                              onClick={() => onIngredentDelete(i, b)}
                            >
                              <TrashIcon className="h-4 w-4 text-gray" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

              <div className="my-7 flex">
                <Button variant="outlined" className="mr-7" onClick={onCancle}>
                  Cancel
                </Button>
                <Button loading={isLoading} onClick={onSubmit}>
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="mt-10">
                <div className="grid w-80">
                  <label
                    className="text-md mb-1 font-bold"
                    htmlFor="product_categorie"
                  >
                    Select Category
                  </label>
                  <select
                    className="w-11/12 rounded-md border-0 md:w-full"
                    id="product_categorie"
                    onChange={(e) => setSelectCategorieInExcel(e.target.value)}
                  >
                    {categorieLists
                      .filter((active) => active.is_active)
                      .map((list, index) => (
                        <option key={index} value={`${list._id}`}>
                          {list.name}
                        </option>
                      ))}
                  </select>
                </div>
                {exclePath.length > 0 ? (
                  <div className="relative mb-7 mt-7 grid h-44 w-44 rounded-md bg-white p-3">
                    <div
                      className="absolute -top-5 -right-3 cursor-pointer rounded-full bg-cultured p-2"
                      onClick={() => setExclePath([])}
                    >
                      <XIcon className="h-5 w-5" />
                    </div>
                    <img
                      className="m-auto h-20 w-20 object-cover"
                      src="https://img.icons8.com/color/480/undefined/ms-excel.png"
                      alt="excel logo"
                    />
                    {exclePath[0]?.name}
                    {isExcelUploadLoading && <Loading />}
                  </div>
                ) : (
                  <div className="border-sky-500 mb-7 mt-7 flex h-44 w-44 items-center justify-center rounded-[12px] border-2 border-dashed border-gray">
                    <label
                      htmlFor="product_excel"
                      className="cursor-pointer rounded-full bg-philippine-gray"
                    >
                      <HeroIcon
                        className="h-10 w-10 p-2 text-white"
                        icon="PlusIcon"
                      />
                      <input
                        className="hidden"
                        type="file"
                        id="product_excel"
                        accept="csv/*"
                        onChange={addExcel}
                      />
                    </label>
                  </div>
                )}
                <div className="mt-7 flex w-fit items-center rounded-md bg-white p-3">
                  <h2>Download Sample CSV File.</h2>
                  <a
                    href="/assets/shop/Product_Sample.csv"
                    target="_blank"
                    download
                  >
                    <DownloadIcon className="ml-2 h-5 w-5 cursor-pointer" />
                  </a>
                </div>

                <div className="my-7 flex">
                  <Button
                    variant="outlined"
                    className="mr-7"
                    onClick={onCancle}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={isExcelUploadLoading}
                    onClick={onExcelSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ProductAdd;
