import React from "react";
import {
  ArchiveIcon,
  BookOpenIcon,
  ClipboardListIcon,
  DeviceTabletIcon,
  HomeIcon,
  ReceiptTaxIcon,
  TemplateIcon,
  UserCircleIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import { ShpoLocalstorage } from "types";
import { DisplayIcon } from "components/ui";

const navigation = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Banners",
    path: "/banner",
    icon: <TemplateIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Category",
    path: "/categorie",
    icon: <ViewGridAddIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Products",
    path: "/product",
    icon: <ArchiveIcon className="1w-5 mx-auto h-5" />,
    cName: "nav-text",
  },
  {
    title: "Order Create",
    path: "/create_order",
    icon: <BookOpenIcon className="1w-5 mx-auto h-5" />,
    cName: "nav-text",
  },
  {
    title: "Orders Queue",
    path: "/order",
    icon: <ClipboardListIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Orders List",
    path: "/orderList",
    icon: <ClipboardListIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Coupons",
    path: "/coupon",
    icon: <ReceiptTaxIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Table's",
    path: "/table_management",
    icon: <DeviceTabletIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Reservations",
    path: "/table_reservations",
    icon: <DeviceTabletIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
  {
    title: "Profile",
    path: "/profile",
    icon: <UserCircleIcon className="mx-auto h-5 w-5" />,
    cName: "nav-text",
  },
];

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const authData = JSON.parse(
    localStorage.getItem("jode_shop")!
  ) as ShpoLocalstorage;
  return (
    <>
      <div className="fixed inset-0 hidden w-52 bg-black text-white lg:block">
        <div className="flex items-center p-5 pb-0">
          <DisplayIcon height={12} width={12} icon={authData?.avatar} />
          <h2 className="text-center text-sm">{authData?.store_name}</h2>
        </div>
        <ul className="mx-auto overflow-auto overflow-x-hidden pl-5 pt-7 scrollbar-hide lg:h-[30rem] xl:h-[37rem] 2xl:h-[42rem]">
          {navigation.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive
                      ? "relative mb-3 flex items-center rounded-l-[2.5rem] bg-white p-4 text-black before:absolute before:top-[-80px] before:right-[-1px] before:h-[80px] before:w-[41px] before:rounded-br-[2.5rem] before:shadow-[0_50px_0_0_#ffffff] before:content-[''] after:absolute after:top-[56px] after:right-[-1px] after:h-[80px] after:w-[41px] after:rounded-tr-[2.5rem] after:shadow-[0_-50px_0_0_#ffffff] after:content-['']"
                      : "relative mb-3 flex items-center p-4"
                  }
                >
                  <div className="mr-2">{item.icon}</div>
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="fixed inset-0 hidden w-24 bg-black text-white md:block lg:hidden">
        <div className="mb-7 items-center p-2 pt-5">
          <DisplayIcon height={12} width={12} icon={authData?.avatar} />
          <h2 className="mt-1 text-center text-xs">{authData?.store_name}</h2>
        </div>
        <ul className="w-full items-center p-2">
          {navigation.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive
                      ? "items-center text-gold"
                      : "items-center text-white"
                  }
                >
                  <div className="p-5">{item.icon}</div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
