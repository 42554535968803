import axios from "axios";
import { toast } from "react-toastify";
import React, { Fragment } from "react";
import API, { IMAGE_URL } from "config/Api";
import { socket } from "routes/ProtectedRoute";
import { BankNotesIcon } from "components/icons";
import { Button, Separate } from "components/ui";
import { QrcodeIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { ShopOrders, ShopType, ShpoLocalstorage } from "types";
import ProductOrderBox from "components/shops/order/ProductOrderBox";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";

interface Props {}

const buttonValues = [
  {
    title: "Cash",
    value: "cash",
    icon: <BankNotesIcon className="mx-auto h-5 w-5" />,
  },
  {
    title: "QR Payment",
    value: "qr payment",
    icon: <QrcodeIcon className="mx-auto h-5 w-5" />,
  },
];

const Order: React.FC<Props> = () => {
  const [viewOrderProducts, setViewOrderProducts] =
    React.useState<Number | null>(null);
  const [ordersList, setOrdersList] = React.useState<ShopOrders | []>([]);
  const [isCashPaymentConfirm, setIsCashPaymentConfirm] = React.useState(false);
  const [cashPaymentOrder, setCashPaymentOrder] = React.useState<any>(null);
  const [paymentMode, setPaymentMode] = React.useState("");
  const [storeData, setStoreData] = React.useState<ShopType | null>(null);
  const [isPaymentCollected, setIsPaymentCollected] = React.useState(false);

  //FUNCTION FOR PLAY SOUND WHEN NEW ORDER RECEVED
  const playVideo = () => {
    const audio = new Audio("/assets/shop/notification.mp3");
    audio.loop = false;
    audio.play();
  };

  const fetchStoreDetails = React.useCallback(() => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `store/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setStoreData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, []);

  const fetchOrderList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
      params: {
        page: 1,
        limit: 10,
        tableNo: "",
        userNo: "",
      },
    };

    const url = API + `getorders/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setOrdersList(response?.data?.data?.Orders);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setViewOrderProducts(0);
      });
  }, []);

  //DROPDOWN TOGGLE FOR MORE DETAIL OF ORDER LIST
  const onViewOrderProduct = (index: any) => {
    if (viewOrderProducts === index) {
      setViewOrderProducts(null);
    } else {
      setViewOrderProducts(index);
    }
  };

  //SELECT ORDER AND OPEN MODEL
  const onCashPayment = (orderId: any) => {
    setCashPaymentOrder(orderId);
    setIsCashPaymentConfirm(!isCashPaymentConfirm);
  };

  //CREATE RANDOM STRING FUNCTION
  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  //COLLECTING PAYMENT BY STAFF CONTAIN 2 PAYMENT MODE "CASH", "QR PAYMENT"
  const onCollectPayment = () => {
    setIsPaymentCollected(true);
    //CHECK FOR PAYMENT MODE SELECTION
    if (paymentMode.trim() === "") {
      toast.warn("Please select payment mode.");
      setIsPaymentCollected(false);
      return;
    }
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    //CREATE BODY FOR CONFIRMING PAYMENT
    const body = {
      orderId: cashPaymentOrder?._id,
      isPaymentDone: true,
      paymentMode: paymentMode,
      paymentId: `rest_pay_${makeid(9)}`,
      transferId: `rest_trf_${makeid(9)}`,
      promoId: null,
    };

    const url = API + `orderPaymentByAdmin`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          //CALL FOR UPDATED ORDER LIST AND CLOSE MODEL
          fetchOrderList();
          toast.success("Payment collected Successfully.");
          setIsCashPaymentConfirm(!isCashPaymentConfirm);
        } else {
          toast.warn(`${response?.data?.data}`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsPaymentCollected(false);
      });
  };

  //GET NEW ORDER LIST FROM SOCKET
  React.useEffect(() => {
    socket?.on("receiveNewOrder", (data) => {
      setOrdersList(data?.Orders);
      // toast.info("You Have New Order.");
      playVideo();
    });
  });

  //GET ORDER PAYMENT AND NEW LIST OF ORDER FROM SOCKET
  React.useEffect(() => {
    socket?.on("receiveOrderPayment", (data) => {
      setOrdersList(data?.Orders);
      // toast.info("Payment Received For Order.");
      playVideo();
    });
  });

  //INITIAL VALUE
  React.useEffect(() => {
    fetchOrderList();
    fetchStoreDetails();
  }, [fetchOrderList, fetchStoreDetails]);

  return (
    <>
      <DashboardHeader title={"Orders Queue"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">Orders Queue</h2>
        <div className="rounded-2xl bg-cultured p-2 md:p-5">
          <div className="mb-4 grid select-none grid-cols-2 items-center font-bold lg:grid-cols-4">
            <h2>Mobile Number</h2>
            <h2>Table Number</h2>
            <h2 className="hidden md:block">Total Order</h2>
            <h2 className="hidden md:block">Total Price</h2>
          </div>
          {ordersList.length < 1 ? (
            <div className="mb-4 rounded-lg bg-white p-2 md:p-5">
              <h2>No Orders Queue For Now.</h2>
            </div>
          ) : (
            ordersList
              ?.filter((active) => active?.orderAcceptStatus)
              ?.map((list, index) => {
                return (
                  <div
                    key={index}
                    className="mb-4 rounded-lg bg-white p-2 md:p-5"
                  >
                    <div className="relative grid grid-cols-2 items-center lg:grid-cols-4">
                      <h2>
                        {list?.userName} ({list?.userNumber})
                      </h2>
                      <h2>{list?.tableNumber}</h2>
                      <h2 className="hidden md:block">{list?.totalOrder}</h2>
                      <h2 className="hidden md:block">₹ {list?.totalPrice}</h2>
                      {viewOrderProducts === index ? (
                        <ChevronDownIcon
                          className="absolute right-5 h-7 w-7 cursor-pointer"
                          onClick={() => onViewOrderProduct(index)}
                        />
                      ) : (
                        <ChevronRightIcon
                          className="absolute right-5 h-7 w-7 cursor-pointer"
                          onClick={() => onViewOrderProduct(index)}
                        />
                      )}
                    </div>
                    {viewOrderProducts === index && (
                      <>
                        <div className="mt-4 grid grid-cols-1 gap-4 border-t-2 border-gray/20 lg:grid-cols-2">
                          {list?.product.map((orderList, index) => {
                            return (
                              <span key={index}>
                                <ProductOrderBox
                                  orderProduct={orderList}
                                  sequence={index}
                                />
                              </span>
                            );
                          })}
                        </div>
                        <div className="mt-2 select-none items-center border-t-2 border-gray/20 md:flex">
                          <button
                            className="mt-2 h-10 w-36 rounded-md border-0 bg-gold font-bold"
                            onClick={() => onCashPayment(list)}
                          >
                            Accept Payment
                          </button>
                          <h2 className="mt-2 md:ml-4">
                            Order Confirm by Cash Payment!
                          </h2>
                        </div>
                      </>
                    )}
                  </div>
                );
              })
          )}
        </div>
      </section>

      <Transition appear show={isCashPaymentConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsCashPaymentConfirm(!isCashPaymentConfirm)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-3 text-center text-lg font-medium capitalize leading-6"
                  >
                    Accept Payment
                  </Dialog.Title>
                  <Separate dark={true} />
                  <div className="my-7">
                    <div className="mb-3">
                      <p>
                        Complete customer payment by providing payment details.
                      </p>
                    </div>
                    <ul className="flex items-center justify-start gap-5">
                      {buttonValues.map((list, index) => {
                        return (
                          <li className="relative w-fit" key={index}>
                            <input
                              type="radio"
                              name="answer"
                              id={list.value}
                              value={list.value}
                              className="peer sr-only"
                              onChange={() => setPaymentMode(list.value)}
                            />
                            <label
                              className="peer-checked:ring-blue flex cursor-pointer  select-none items-center gap-2 rounded border-2 border-gray bg-white px-6 py-4 text-sm font-bold capitalize focus:outline-none peer-checked:border-transparent peer-checked:bg-gold peer-checked:text-black"
                              htmlFor={list.value}
                            >
                              {list.icon} {list.title}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                    {paymentMode === "cash" && (
                      <div className="my-3 rounded bg-cultured p-2 ">
                        <p>
                          Make sure you receved {cashPaymentOrder?.totalPrice}{" "}
                          RS form customer.
                        </p>
                      </div>
                    )}
                    {paymentMode === "qr payment" && (
                      <div>
                        <div className="my-3 rounded bg-cultured p-2 ">
                          <p>
                            Make sure you receved {cashPaymentOrder?.totalPrice}{" "}
                            RS form customer.
                          </p>
                        </div>
                        {storeData?.paymentQrImage === null ? (
                          <p>
                            Informed your administrator to upload a payment QR
                            image.
                          </p>
                        ) : (
                          <img
                            className="mx-auto my-5 h-52 w-52"
                            src={`${IMAGE_URL}${storeData?.paymentQrImage}`}
                            alt={`${storeData?.shop_name}`}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-end gap-5">
                    <Button
                      onClick={onCollectPayment}
                      loading={isPaymentCollected}
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() =>
                        setIsCashPaymentConfirm(!isCashPaymentConfirm)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Order;
