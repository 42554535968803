import React from "react";
import Order from "pages/shop/Orders";
import Coupon from "pages/shop/Coupon";
import { Loading } from "components/ui";
import Banner from "pages/shop/Banners";
import Product from "pages/shop/Products";
import OrderList from "pages/shop/OrderList";
import Categorie from "pages/shop/Categories";
import PageNotFound from "pages/PageNotFound";
import ProductAdd from "pages/shop/ProductAdd";
import ProtectedRoutes from "./ProtectedRoute";
import ShopProfile from "pages/shop/ShopProfile";
import { Route, Routes } from "react-router-dom";
import CreateOrder from "pages/shop/CreateOrder";
import OrderRequest from "pages/shop/OrderRequest";
import UpdateProduct from "pages/shop/UpdateProduct";
import TableManagement from "pages/shop/TableManagement";
import DashboardLayout from "components/common/DashboardLayout";
import TableReservation from "pages/shop/TableReservation";

const Auth = React.lazy(() => import("pages/index"));
const Shop = React.lazy(() => import("pages/shop"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <React.Suspense fallback={<Loading />}>
            <PageNotFound />
          </React.Suspense>
        }
      />
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loading />}>
            <Auth />
          </React.Suspense>
        }
      />

      {/* SHOP PAGES ROUTES */}

      <Route element={<ProtectedRoutes />}>
        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={
              <React.Suspense fallback={<Loading />}>
                <Shop />
              </React.Suspense>
            }
          />
          <Route
            path="/banner"
            element={
              <React.Suspense fallback={<Loading />}>
                <Banner />
              </React.Suspense>
            }
          />
          <Route
            path="/categorie"
            element={
              <React.Suspense fallback={<Loading />}>
                <Categorie />
              </React.Suspense>
            }
          />
          <Route
            path="/product"
            element={
              <React.Suspense fallback={<Loading />}>
                <Product />
              </React.Suspense>
            }
          />
          <Route
            path="/addProduct"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProductAdd />
              </React.Suspense>
            }
          />
          <Route
            path="/updateProduct/:id"
            element={
              <React.Suspense fallback={<Loading />}>
                <UpdateProduct />
              </React.Suspense>
            }
          />
          <Route
            path="/create_order"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateOrder />
              </React.Suspense>
            }
          />
          <Route
            path="/order_request"
            element={
              <React.Suspense fallback={<Loading />}>
                <OrderRequest />
              </React.Suspense>
            }
          />
          <Route
            path="/order"
            element={
              <React.Suspense fallback={<Loading />}>
                <Order />
              </React.Suspense>
            }
          />
          <Route
            path="/orderList"
            element={
              <React.Suspense fallback={<Loading />}>
                <OrderList />
              </React.Suspense>
            }
          />
          <Route
            path="/coupon"
            element={
              <React.Suspense fallback={<Loading />}>
                <Coupon />
              </React.Suspense>
            }
          />
          <Route
            path="/table_management"
            element={
              <React.Suspense fallback={<Loading />}>
                <TableManagement />
              </React.Suspense>
            }
          />
          <Route
            path="/table_reservations"
            element={
              <React.Suspense fallback={<Loading />}>
                <TableReservation />
              </React.Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <React.Suspense fallback={<Loading />}>
                <ShopProfile />
              </React.Suspense>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
