import React from "react";
import axios from "axios";
import moment from "moment";
import API from "config/Api";
import { Table } from "components/ui";
import Flatpickr from "react-flatpickr";
import { ShpoLocalstorage } from "types";
import "flatpickr/dist/themes/material_green.css";
import { CalendarIcon, SearchIcon } from "@heroicons/react/solid";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import OrderProductListModel from "components/shops/order/OrderProductListModel";

interface Props {}

const OrderList: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [orderPayedList, setOrderPayedList] = React.useState([]);
  const [picker, setPicker] = React.useState<any[]>([]);
  const [searchFromList, setSearchFromList] = React.useState<string>("");
  const [viewProducts, setViewProducts] = React.useState([]);
  const [isViewProduct, setIsViewProduct] = React.useState(false);

  const fetchPayedOrderList = React.useCallback(() => {
    setIsLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
      params: {
        page: "",
        limit: "",
        search: searchFromList,
        startDate: picker[0] || "",
        endDate: picker[1] || "",
      },
    };
    const url = API + `getPayedOrderList/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setOrderPayedList(response?.data?.data?.payedOrder);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [picker, searchFromList]);
  const onViewProductList = (list: any) => {
    setViewProducts(list);
    setIsViewProduct(true);
  };

  const onRazorpayAmountCalculate = (
    paidAmount: any,
    razorpay_charge: any,
    tax_value: any
  ) => {
    // TOTAL PRICE = TP (Order price)
    // RAZORPAY PERCENTAGE = RP (normal cards, UPI, and etc = 2% | International cards = 3%)
    // RAZORPAY FEES = RF (calculation from TP and RP)
    // RAZORPAY TAX = RT (calculation from TP and RP)
    // TAX AMOUNT = TA (razorpay_tax amount 18% on RF)
    // RAZORPAY TAX AMOUNT ON ON FINAL AMOUNT = TXA
    // FINAL AMOUNT = FA
    //HC = HANDLING CHARGE (0.50%)
    //HT = HANDLING TAX
    //HCG = HANDLING CHARGE GST(18%)
    //FHC = FINAL HANDLING CHARGE

    let TP = paidAmount; //IN PERCENTAGE
    let RP = razorpay_charge / 100; //IN PERCENTAGE
    let RT = tax_value / 100; //IN PERCENTAGE

    let RF = TP * RP; //RF
    let TA = RF * RT; //TA
    let FA = RF + TA;

    //CALCULATION FOR HANDLING CHARGE
    const HC = 0.5;
    const HT = TP * (HC / 100);
    const HCG = (HT * 18) / 100;
    const FHC = HT + HCG;
    //CALCULATION FOR HANDLING CHARGE

    let transferAmount = (TP - FA - FHC).toFixed(2);
    return transferAmount;
  };

  //SET ORDER LIST IN DATA TO REPRESENT IN TABLE FORMATE
  const data = React.useMemo(() => orderPayedList, [orderPayedList]);

  //CREATE TABLE COLUMNS
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "userName",
        Cell: ({ row }: any) => (
          <div className="flex items-center capitalize">
            {row?.original?.userName === undefined
              ? row?.original?.userNumber
              : row?.original?.userName || "----------"}
          </div>
        ),
      },
      {
        Header: "Order Type",
        accessor: "orderType",
      },
      {
        Header: "Table No",
        accessor: "tableNumber",
      },
      {
        Header: "Orders",
        accessor: "totalOrder",
      },
      {
        Header: "Price",
        accessor: "totalPrice",
        Cell: ({ row }: any) => (
          <div>
            {row?.original?.paidAmount === "NaN"
              ? row?.original?.totalPrice
              : onRazorpayAmountCalculate(row?.original?.paidAmount, 2, 18)}
          </div>
        ),
      },
      {
        Header: "Payment Type",
        accessor: "paymentMode",
      },
      {
        Header: "Coupon",
        accessor: "promoId.promoCode",
        Cell: ({ row }: any) => (
          <div className="flex items-center">
            {row?.original?.promoId?.promoCode || "-----"}
          </div>
        ),
      },
      {
        Header: "Payment Id",
        accessor: "paymentId",
      },
      {
        Header: "transfer Id",
        accessor: "transferId",
      },
      {
        Header: "Order Date",
        accessor: "createdAt",
        Cell: ({ row }) => (
          <>{moment(row.original.createdAt).format("D MMMM YYYY")}</>
        ),
      },
      {
        Header: "View Orders",
        accessor: "orders",
        Cell: ({ row }) => (
          <div
            className="cursor-pointer rounded bg-gold px-3 py-2 text-sm text-raisin-black"
            onClick={() => onViewProductList(row.original.product)}
          >
            View Products
          </div>
        ),
      },
    ],
    []
  );

  React.useEffect(() => {
    fetchPayedOrderList();
  }, [fetchPayedOrderList]);

  return (
    <>
      <DashboardHeader title={"Orders List"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <div className="mb-5 flex items-center justify-between">
          <h2 className="mb-3 hidden text-xl font-bold md:block">
            Orders List
          </h2>
          <div className="grid grid-cols-2 items-center gap-5">
            <div className="relative h-10 w-full xl:w-auto">
              <CalendarIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
              <Flatpickr
                value={picker}
                id="range-picker"
                className="w-full rounded-md border-none bg-cultured pl-9 font-medium placeholder:font-medium placeholder:text-gray focus:ring-0 xl:w-[300px]"
                placeholder="Selecte Date"
                options={{
                  mode: "range",
                }}
                onChange={(data) => setPicker(data)}
              />
            </div>
            <div className="relative h-10 w-full xl:w-auto">
              <SearchIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
              <input
                type="search"
                className="w-full rounded-md border-none bg-cultured pl-9 font-medium placeholder:font-medium placeholder:text-gray focus:ring-0 xl:w-[300px]"
                placeholder="Search user..."
                onChange={(e) => setSearchFromList(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="rounded-xl bg-cultured p-2 px-4">
          <Table columns={columns} data={data} loading={isLoading} />
        </div>
      </section>
      <OrderProductListModel
        onOpen={isViewProduct}
        productList={viewProducts}
        onClose={() => setIsViewProduct(false)}
      />
    </>
  );
};

export default OrderList;
