import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import API, { IMAGE_URL } from "config/Api";
import { socket } from "routes/ProtectedRoute";
import ShowMoreText from "react-show-more-text";
import { Button, QuantityButton } from "components/ui";
import CustomerDetailsMode from "./CustomerDetailsMode";
import { ShpoLocalstorage, StoreTableOblect } from "types";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";

interface UserDetails {
  userName?: string;
  userNumber?: string;
}

interface Props {
  tableDeatils: StoreTableOblect | null;
  orderStatus: any;
}

const StaffViewOrder: React.FC<Props> = ({ tableDeatils, orderStatus }) => {
  const orderStatusHandler = orderStatus;
  const [localCartProduct, setLocalCartProduct] = React.useState<any>(null);
  const [viewOrderIngredient, setViewOrderIngredient] =
    React.useState<Number | null>(null);
  const [promoCode, setPromoCode] = React.useState("");
  const [totalPayAmount, setTotalPayAmount] = React.useState<Number | 0>(0);
  const [promoCodeDeatils, setpromoCodeDeatils] = React.useState<any>(null);
  const [isPromoInRupee, setIsPromoInRupee] = React.useState(true);
  const [isOrderCreateLoading, setIsOrderCreateLoading] =
    React.useState<boolean>(false);
  const [isCustomerModel, setIsCustomerModel] = React.useState(false);

  //VIEW ADDED ADDONS IN PRODUCT
  const onViewOrderIngredient = (index: any) => {
    if (viewOrderIngredient === index) {
      setViewOrderIngredient(null);
    } else {
      setViewOrderIngredient(index);
    }
  };

  //UPDATE / DELETE PRODUCT QUANTITY
  const onProductUpdateInCart = async (quantity: any, index: any) => {
    //GET CART PRODUCT FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption") as any
    );
    if (quantity === 0) {
      jode_cart.product.splice(index, 1);
      //CALCUTATE TOTAL PRICE OF PRODUCT AFTER REMOVE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: tableDeatils?.table_name,
        totalOrder: jode_cart?.totalOrder - 1,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jode_staff_caption", JSON.stringify(updateOrder));
      //setOrderCartLocalCount(updateOrder?.product?.length);
      //SET IN USESTATE FOR VIEW UPDATE
      //SEND FINAL ORDER IN STATE->
      setLocalCartProduct(updateOrder);
      setTotalPayAmount(Number(updateOrder?.totalPrice));
    } else {
      //FIND PRODUCT IN LOCALSTORAGE WITH INDEX
      const addonsIndex = jode_cart.product.findIndex(
        (x: any) => x.productId?._id === jode_cart.product[index].productId?._id
      );
      //CRATE UPDATED PRODUCT BODY WITH INDEX
      const addProduct = {
        productId: jode_cart.product[index].productId,
        productQty: quantity,
        productPrice: jode_cart.product[index].productPrice,
        adonsPrice: jode_cart.product[index].adonsPrice,
        totalPrice:
          (jode_cart.product[index].productPrice +
            jode_cart.product[index].adonsPrice) *
          quantity,
        addonsList: jode_cart.product[index].addonsList,
      };
      //SET UPDATED PRODUCT ON SAME INDEX
      jode_cart.product[addonsIndex] = addProduct;
      //CALCUTATE TOTAL PRICE OF PRODUCT
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: tableDeatils?.table_name,
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jode_staff_caption", JSON.stringify(updateOrder));
      //SET IN USESTATE FOR VIEW UPDATE
      setLocalCartProduct(updateOrder);
      setTotalPayAmount(Number(updateOrder?.totalPrice));
    }
  };

  //FUNCTION FOR APPLY PROMO CODE
  const onPromoCodeCheck = () => {
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    //CHECK FOR PROMO CODE
    if (promoCode.trim() === "") {
      toast.warn("Please Enter Promo Code Before Applying.");
    } else {
      //PROMO CODE BODY
      const body = {
        storeId: authData?.id,
        promoId: "",
        promoCode: promoCode,
        price: parseFloat(localCartProduct?.totalPrice).toFixed(2),
      };

      let url = API + `promoShop`;
      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success) {
            toast.success(response?.data?.message);
            setTotalPayAmount(response?.data?.data?.finalDiscount);
            setpromoCodeDeatils(response?.data?.data?.promoPrice);
            setIsPromoInRupee(response?.data?.data?.isInRupee);
          } else {
            toast.warn(response?.data?.message);
          }
        })
        .catch(function (error) {
          console.log("Error", error.message);
        });
    }
  };

  //REMOVE PROMO CODE AFTER APPLYING
  const onPromoCodeRemove = () => {
    setPromoCode("");
    setpromoCodeDeatils(null);
    setTotalPayAmount(Number(localCartProduct?.totalPrice));
  };

  //CHECK FOR RAZORPAY KEY IN STORE
  const onCheckForStore = (userDetails: UserDetails) => {
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `getShop`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          //RAZORPAY KEY / USER DETAILS
          onOrderFromCart(response?.data?.data?.razorpay_acc, userDetails);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  //CREATE KOT ORDER FOR STORE BY STAFF
  const onOrderFromCart = async (
    onStoreData: string | null,
    userDetails: UserDetails
  ) => {
    setIsOrderCreateLoading(true);
    //CHECK FOR STORE RAZORPAY ACCOUNT DETAILS
    if (onStoreData === null) {
      toast.warn("This Store Is Under Review You Can't Order Now.");
    } else {
      //AUTH TOKEN
      const authData = JSON.parse(
        localStorage.getItem("jode_shop")!
      ) as ShpoLocalstorage;
      const config = {
        headers: {
          Authorization: `Bearer ${authData?.accessToken}`,
        },
      };
      //CHECK FOR PRODUCT IN CART
      if (
        localCartProduct?.product?.length === 0 ||
        localCartProduct?.product?.length === undefined
      ) {
        toast.warn("Add Some Product For Order.");
        setIsOrderCreateLoading(false);
      } else {
        let dummy_ary = [] as any[];
        //CREATE PRODUCT BODY RESPECTED TO API FOR CREATE KOT PROCESS
        for (let i = 0; i < localCartProduct?.product.length; i++) {
          const element = localCartProduct?.product[i];
          const body = {
            productId: element?.productId?._id,
            productQty: element?.productQty,
            productPrice: element?.productId?.price,
            adonsPrice: element?.adonsPrice,
            totalPrice: element?.totalPrice,
            addonsList: element?.addonsList,
            orderAcceptStatus: true,
          };
          dummy_ary.push(body);
        }
        //CREATE BODY RESPECTED TO API FOR CREATE KOT PROCESS
        const orderBody = {
          storeId: authData?.id,
          userName: userDetails?.userName,
          userNumber: userDetails?.userNumber,
          tableNumber: tableDeatils?.table_name,
          tableId: tableDeatils?._id,
          totalOrder: dummy_ary.length,
          totalPrice: localCartProduct?.totalPrice,
          orderType: "dine_in",
          product: [...dummy_ary],
          orderAcceptStatus: true,
        };

        const url = API + `orderCreate`;
        axios
          .post(url, orderBody, config)
          .then((response) => {
            if (response?.data?.success) {
              //EMIT DATA IN SOCKET
              socket?.emit("sendNewOrder", authData?.id);
              localStorage.removeItem("jode_staff_caption");
              //setOrderCartLocalCount(0);
              //   toast.success(`Ordered placed Successfully.`);
              orderStatusHandler(response?.data);
              //navigate(`/shop/view_order`);
              //closeHandler();
            } else {
              toast.warn(`${response?.data?.data}`);
            }
          })
          .catch((err) => {
            console.log("error here", err);
          })
          .finally(() => {
            setIsOrderCreateLoading(false);
          });
      }
    }
  };

  //CHECK FOR ORDER IF IT'S REPERTING ORDER DO NOT ASKED FOR USER DETAILS
  const onCreateOrder = () => {
    //CHECK FOR PRODUCT ADDED OR NOT
    if (!(localCartProduct?.product?.length > 0)) {
      toast.warn("Select some product for creating order.");
      return;
    }
    if (tableDeatils?.storeOrderId === null) {
      setIsCustomerModel(true);
    } else {
      //SET USER DETAILS FROM PREVIOUS ORDER
      const data = {
        userName: tableDeatils?.storeOrderId?.userName,
        userNumber: tableDeatils?.storeOrderId?.userNumber,
      } as UserDetails;
      onCheckForStore(data);
    }
  };

  //INITIAL VALUE
  React.useEffect(() => {
    //ADD CART DETAIL FROM LOCALSTOREAGE TO STATE
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption") as any
    );
    setLocalCartProduct(jode_cart);
    //ADD TOTAL PICE VALUE IN STAT
    const total = parseFloat(jode_cart?.totalPrice).toFixed(2);
    setTotalPayAmount(Number(total));
  }, []);

  return (
    <>
      <div className="mt-7">
        {!(localCartProduct?.product?.length > 0) ? (
          <div className="my-10">
            <img
              className="mx-auto"
              src="/assets/shop/order_empty.png"
              alt="order_empty"
            />
            <div className="text-center capitalize">
              <h2 className="mb-2 text-2xl font-bold">No Orders Yet</h2>
              <p>
                Your cart is empty. <br /> Add something from the menu
              </p>
            </div>
          </div>
        ) : (
          <>
            {localCartProduct?.product.map((list: any, index: any) => {
              return (
                <div className="mb-5 rounded-md bg-cultured p-2" key={index}>
                  <div className="flex">
                    <div className="mr-2">
                      <img
                        className="h-24 w-24 rounded-md object-cover"
                        src={`${IMAGE_URL}${list?.productId?.image[0]}`}
                        alt={list?.productId?.name}
                      />
                    </div>
                    <div className="w-fit">
                      <h2 className="mb-1 text-lg font-bold">
                        <ShowMoreText
                          lines={1}
                          more=""
                          less=""
                          className="w-full"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {list?.productId?.name}
                        </ShowMoreText>
                      </h2>
                      <h2>
                        <ShowMoreText
                          lines={2}
                          more=""
                          less=""
                          className="text-sm"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {list?.productId?.description}
                        </ShowMoreText>
                      </h2>
                      <div className="flex items-center">
                        {list?.productId?.isVeg ? (
                          <img
                            className="h-6 w-6"
                            src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                            alt="non-veg"
                          />
                        ) : (
                          <img
                            className="h-6 w-6"
                            src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                            alt="veg"
                          />
                        )}
                        <p className="ml-2 text-lg font-bold">
                          ₹ {list?.productId?.price}
                        </p>
                      </div>
                    </div>
                  </div>
                  {list?.addonsList.length !== 0 && (
                    <div className="flex items-start justify-between">
                      {viewOrderIngredient === index ? (
                        <div className="w-full pb-1">
                          {list?.addonsList.map((ing: any, index2: any) => {
                            return (
                              <div
                                className="my-0.5 flex items-center justify-between text-sm font-medium"
                                key={index2}
                              >
                                <h2 className="text-gray">
                                  {ing?.ingredient_name}
                                </h2>
                                <h3>₹ {ing?.ingredient_price}</h3>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="w-full pb-1">
                          <div className="my-0.5 flex items-center justify-between text-sm font-medium">
                            <h2 className="text-gray">Addons</h2>
                            <h3>
                              ₹ {localCartProduct.product[index].adonsPrice}
                            </h3>
                          </div>
                        </div>
                      )}

                      {viewOrderIngredient === index ? (
                        <div className="pt-1">
                          <ChevronDownIcon
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => onViewOrderIngredient(index)}
                          />
                        </div>
                      ) : (
                        <div className="pt-1">
                          <ChevronRightIcon
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => onViewOrderIngredient(index)}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex items-center justify-between border-t-2 border-dashed border-gray/30">
                    <div className="mt-2 h-fit w-fit">
                      <QuantityButton
                        small={true}
                        color="bg-white"
                        qty={list?.productQty}
                        onChange={(e) => onProductUpdateInCart(e, index)}
                      />
                    </div>
                    <div className="flex justify-end font-medium">
                      <h2 className="pr-3">₹ {list?.totalPrice}</h2>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
        <div className="sticky bottom-5 mx-auto h-fit w-full rounded-xl border border-black/10 bg-cultured p-3 shadow-xl md:w-full lg:w-[48rem]">
          {/* <div className="flex items-center justify-between rounded-md border-2 border-dashed border-gray/50 pr-0.5">
            <input
              type="text"
              value={promoCode}
              id="promo_code_shop"
              name="promo_code_shop"
              placeholder="Apply Promo code"
              onChange={(e) => setPromoCode(e.target.value)}
              className="w-full border-0 bg-transparent focus:outline-none focus:outline-0"
            />
            <div className="flex items-center gap-1">
              <button
                onClick={onPromoCodeCheck}
                className="rounded-md border-0 bg-gold p-2 px-4 font-bold"
              >
                <CheckIcon className="h-5 w-5" />
              </button>
              {promoCodeDeatils !== null && (
                <button
                  onClick={onPromoCodeRemove}
                  className="rounded-md border-0 bg-gold p-2 px-4 font-bold"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div> */}
          {/* <div className="my-3 flex items-center justify-between border-t border-gray/50 pt-3 font-medium text-gray">
            <div className="space-y-1">
              <h2>Subtotal</h2>
              {promoCodeDeatils !== null && <h2>Promo Code</h2>}
              <h2 className="text-black">To Pay</h2>
            </div>
            <div className="space-y-1 text-right">
              <h2>₹ {localCartProduct?.totalPrice || "00.00"}</h2>
              {promoCodeDeatils !== null && (
                <h2 className="text-[#FF0000]">
                  -{promoCodeDeatils.toFixed(2)} {isPromoInRupee ? "₹" : "%"}
                </h2>
              )}
              <h2 className="text-black">₹ {totalPayAmount || "00.00"}</h2>
            </div>
          </div> */}
          <div>
            <Button
              width={"100%"}
              loading={isOrderCreateLoading}
              onClick={() => onCreateOrder()}
            >
              Create Order ₹ {totalPayAmount || "00.00"}
            </Button>
          </div>
        </div>
      </div>

      <CustomerDetailsMode
        onOpen={isCustomerModel}
        orderPlaced={onCheckForStore}
        onClose={() => setIsCustomerModel(false)}
      />
    </>
  );
};

export default StaffViewOrder;
