import React from "react";
import { ShpoLocalstorage } from "types";
import { DisplayIcon, Separate } from "components/ui";
import { NavLink } from "react-router-dom";
import useOutsideClick from "lib/hooks/useOutsideClick";
import {
  ArchiveIcon,
  BookOpenIcon,
  ClipboardListIcon,
  DeviceTabletIcon,
  HomeIcon,
  ReceiptTaxIcon,
  TemplateIcon,
  UserCircleIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { ChevronRightIcon, MenuAlt2Icon } from "@heroicons/react/solid";

interface Props {
  title: string;
}

const DashboardHeader: React.FC<Props> = ({ title }) => {
  const authData = JSON.parse(
    localStorage.getItem("jode_shop")!
  ) as ShpoLocalstorage;
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);
  const ref = React.useRef<any>();

  const navigation = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <HomeIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Banners",
      path: "/banner",
      icon: <TemplateIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Category",
      path: "/categorie",
      icon: <ViewGridAddIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Products",
      path: "/product",
      icon: <ArchiveIcon className="1w-5 mx-auto h-5" />,
      cName: "nav-text",
    },
    {
      title: "Orders Queue",
      path: "/order",
      icon: <ClipboardListIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Order Create",
      path: "/create_order",
      icon: <BookOpenIcon className="1w-5 mx-auto h-5" />,
      cName: "nav-text",
    },
    {
      title: "Orders List",
      path: "/orderList",
      icon: <ClipboardListIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Coupons",
      path: "/coupon",
      icon: <ReceiptTaxIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Table's",
      path: "/table_management",
      icon: <DeviceTabletIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Reservations",
      path: "/table_reservations",
      icon: <DeviceTabletIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
    {
      title: "Profile",
      path: "/profile",
      icon: <UserCircleIcon className="mx-auto h-5 w-5" />,
      cName: "nav-text",
    },
  ];

  const onToggle = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useOutsideClick(ref, () => {
    if (isToggleOpen) {
      setIsToggleOpen(false);
    }
  });

  return (
    <>
      <div className="relative z-50 block p-3 pt-7 text-center md:hidden">
        {isToggleOpen && (
          <div
            className="h-100 absolute top-0 left-0 w-52 bg-black pb-10 text-white"
            ref={ref}
          >
            <div className="flex items-center p-5">
              <DisplayIcon height={12} width={12} icon={authData?.avatar} />
              <h2 className="text-xs">{authData?.store_name}</h2>
            </div>
            <ul className="pl-5">
              {navigation.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        isActive
                          ? "relative mb-3 flex items-center rounded-l-[2.5rem] bg-white p-4 text-black before:absolute before:top-[-80px] before:right-[-1px] before:h-[80px] before:w-[41px] before:rounded-br-[2.5rem] before:shadow-[0_50px_0_0_#ffffff] before:content-[''] after:absolute after:top-[56px] after:right-[-1px] after:h-[80px] after:w-[41px] after:rounded-tr-[2.5rem] after:shadow-[0_-50px_0_0_#ffffff] after:content-['']"
                          : "relative mb-3 flex items-center p-4"
                      }
                    >
                      <div className="mr-2">{item.icon}</div>
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="mb-2 flex items-center justify-between">
          <MenuAlt2Icon className="h-8 w-8" onClick={onToggle} />
          <h2 className="text-2xl font-bold">{title}</h2>
          <ChevronRightIcon className="h-8 w-8" />
        </div>
        <Separate dark={true} />
      </div>
    </>
  );
};

export default DashboardHeader;
