import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { socket } from "routes/ProtectedRoute";
import { ShopOrderObject, ShpoLocalstorage } from "types";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  onClose: any;
  onOpen: boolean;
  orderAccept: () => void;
  orderDetails: ShopOrderObject | null;
}

const OrderAcceptModel: React.FC<Props> = ({
  onOpen,
  onClose,
  orderDetails,
  orderAccept,
}) => {
  const closeHandler = onClose;
  const orderAcceptcloseHandler = orderAccept;

  //VERIFY ORDER AND PROCESS FOR POS WITH SOCKET
  const onSubmit = () => {
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    const url = API + `order/update`;
    const body = {
      _id: orderDetails?._id,
      orderAcceptStatus: true,
    };
    axios
      .patch(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          //CALL SOCKET ORDER LIST AND CLOSE MODEL AFTER SUCCESSFULLY UPDATE ORDER
          closeHandler();
          socket?.emit("sendNewOrder", authData?.id);
          toast.success(response?.data?.message);
          orderAcceptcloseHandler();
        } else {
          //IF NOT SHOW ERROR MESSAGE
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  return (
    <Transition appear show={onOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeHandler}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 text-lg font-medium leading-6"
                >
                  Accept Order
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-gray-500 text-sm">
                    Are you sure you want to accept this order?
                  </p>
                </div>

                <div className="mt-4 flex space-x-3">
                  <button
                    type="button"
                    className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                    onClick={onSubmit}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                    onClick={closeHandler}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderAcceptModel;
