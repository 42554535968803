import React from "react";
import {
  TrashIcon,
  BookmarkIcon,
  PencilAltIcon,
  BookmarkAltIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { StoreTableOblect } from "types";
import { WaiterCallIcon } from "components/icons";

interface Props {
  icon: boolean;
  onDelete?: any;
  onUpdate?: any;
  onActive?: any;
  onWaiter?: any;
  onLoading?: boolean;
  tableList: StoreTableOblect;
  onWaiterNote?: any;
  bgColor?: string;
}

const TableBox: React.FC<Props> = ({
  onLoading,
  tableList,
  onDelete,
  onUpdate,
  onActive,
  icon,
  onWaiter,
  onWaiterNote,
  bgColor = "bg-cultured",
}) => {
  const activeHandler = onActive;
  const updateHandler = onUpdate;
  const deleteHandler = onDelete;
  const waiterHandler = onWaiter;
  const waiterNoteHandler = onWaiterNote;

  //DEFINE TABLE STATUS AVAILABILITY : GRAY | RESERVED : RED | ORDERED : GOLD
  const onTableAvailabilityStatus = (status: StoreTableOblect) => {
    if (status.reservation) {
      return "h-3 w-14 rounded-full bg-red";
    } else if (status.availability) {
      return "h-3 w-14 rounded-full bg-gray";
    } else if (status.availability === false) {
      return "h-3 w-14 rounded-full bg-gold";
    }
  };
  //DEFINE TABLE STATUS AVAILABILITY : GRAY | RESERVED : RED | ORDERED : GOLD
  const onTableAvailabilitySideStatus = (status: StoreTableOblect) => {
    if (status.reservation) {
      return "h-9 w-3 rounded-full bg-red";
    } else if (status.availability) {
      return "h-9 w-3 rounded-full bg-gray";
    } else if (status.availability === false) {
      return "h-9 w-3 rounded-full bg-gold";
    }
  };
  //DEFINE TABLE STATUS AVAILABILITY : GRAY | RESERVED : RED | ORDERED : GOLD
  const onTableAvailabilityBorderStatus = (status: StoreTableOblect) => {
    if (status.reservation) {
      return "m-2 mx-3 grid h-24 w-full items-center rounded-xl border-l-8 border-red bg-white p-2 px-3 shadow-xl";
    } else if (status.availability) {
      return "m-2 mx-3 grid h-24 w-full items-center rounded-xl border-l-8 border-gray bg-white p-2 px-3 shadow-xl";
    } else if (status.availability === false) {
      return "m-2 mx-3 grid h-24 w-full items-center rounded-xl border-l-8 border-gold bg-white p-2 px-3 shadow-xl";
    }
  };

  const onTableStatus = (status: StoreTableOblect) => {
    if (status.reservation) {
      return "Reserved";
    } else if (status.availability) {
      return "Free";
    } else if (status.availability === false) {
      return "Ordered";
    }
  };

  return (
    <>
      <div
        className={classNames(
          tableList?.waiter?.isCalled
            ? `relative h-fit w-full select-none rounded-2xl border border-gold ${bgColor} p-5`
            : `h-fit w-full select-none rounded-2xl ${bgColor} p-5`
        )}
      >
        {tableList?.waiter?.isCalled && (
          <div
            onClick={() => waiterNoteHandler(tableList, true)}
            className="absolute -right-5 -top-5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gold px-2 text-raisin-black"
          >
            <WaiterCallIcon className="h-5 w-5" />
          </div>
        )}
        <div className={classNames(icon ? "px-5 pb-3" : "px-5")}>
          <div className="flex justify-evenly">
            <div className={onTableAvailabilityStatus(tableList)}></div>
            <div className={onTableAvailabilityStatus(tableList)}></div>
          </div>
          <div className="flex justify-between">
            <div className="grid items-center">
              <div className={onTableAvailabilitySideStatus(tableList)}></div>
              <div className={onTableAvailabilitySideStatus(tableList)}></div>
            </div>
            <div className={onTableAvailabilityBorderStatus(tableList)}>
              <div className="flex items-baseline justify-between">
                <h2 className="mb-0 text-xl font-semibold">
                  {tableList?.table_name}
                </h2>
                {tableList?.storeOrderId !== null && (
                  <h2 className="mb-0 text-xl font-semibold">
                    ₹ {tableList?.storeOrderId?.totalPrice}
                  </h2>
                )}
              </div>
              <div className="flex items-baseline justify-between font-medium">
                <h3>{onTableStatus(tableList)}</h3>
                <h2 className="text-xs uppercase">
                  {tableList.table_location}
                </h2>
              </div>
            </div>
            <div className="grid items-center">
              <div className={onTableAvailabilitySideStatus(tableList)}></div>
              <div className={onTableAvailabilitySideStatus(tableList)}></div>
            </div>
          </div>
          <div className="flex justify-evenly">
            <div className={onTableAvailabilityStatus(tableList)}></div>
            <div className={onTableAvailabilityStatus(tableList)}></div>
          </div>
        </div>
        {icon && (
          <div className="flex justify-between border-t border-gray/20 px-5 pt-3 text-philippine-gray">
            {tableList?.waiter?.isCalled && (
              <div className="group relative">
                <WaiterCallIcon
                  className="mr-3 h-5 w-5 cursor-pointer"
                  onClick={() =>
                    waiterHandler(tableList?._id, tableList?.availability)
                  }
                />
                <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                  Waiter
                </p>
              </div>
            )}
            {tableList?.availability ? (
              <>
                <div className="group relative">
                  <BookmarkIcon
                    className="mr-3 h-5 w-5 cursor-pointer"
                    onClick={() =>
                      activeHandler(tableList?._id, tableList?.availability)
                    }
                  />
                  <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                    Free
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="group relative">
                  <BookmarkAltIcon
                    className="mr-3 h-5 w-5 cursor-pointer"
                    onClick={() =>
                      activeHandler(tableList?._id, tableList?.availability)
                    }
                  />
                  <p className="absolute -top-6 -left-5 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                    Reserved
                  </p>
                </div>
              </>
            )}
            <div className="group relative">
              <PencilAltIcon
                className="mr-3 h-5 w-5 cursor-pointer"
                onClick={() => updateHandler(tableList)}
              />
              <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                Update
              </p>
            </div>
            <div className="group relative">
              <TrashIcon
                className="mr-3 h-5 w-5 cursor-pointer"
                onClick={() => deleteHandler(tableList?._id)}
              />
              <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                Delete
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TableBox;
