import _ from "lodash";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import React, { Fragment } from "react";
import { ShopProduct, ShpoLocalstorage } from "types";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import ProductBox from "components/shops/product/ProductBox";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

interface Props {}

const Product: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [productsList, setProductsList] = React.useState<ShopProduct | []>([]);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [productDeleteId, setProductDeleteId] = React.useState("");
  const [searchProduct, setSearchProduct] = React.useState("");
  let navigate = useNavigate();

  const fetchProductList = React.useCallback(() => {
    setIsLoading(true);
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
      params: {
        page: "",
        limit: "",
        search: searchProduct,
      },
    };
    const url = API + `product/getallbystore/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          setProductsList(response?.data?.data?.Products);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchProduct]);

  const linksDisplay = _.groupBy(
    productsList
      ?.sort((a: any, b: any) =>
        a?.category_id?.priority > b?.category_id?.priority ? 1 : -1
      )
      ?.filter((active) => active.isActive),
    "category_id.name"
  );

  const onUpdateProduct = (id: any) => {
    navigate(`/updateProduct/${id}`);
  };

  function openDeleteModal(id: any) {
    setIsDeleteModal(true);
    setProductDeleteId(id);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
  }

  const onDeleteProduct = () => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `deleteproduct/${productDeleteId}`;
    axios
      .delete(url, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchProductList();
          closeDeleteModal();
          setProductDeleteId("");
          toast.success(`Product Deleted Successfully.`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onProductStockControl = (id: any, status: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const body = {
      isInStock: !status,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `productstock/${id}`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchProductList();
          toast.success(
            `Product ${!status ? "Stock In" : "Stock Out"} Successfully.`
          );
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onActiveProduct = (id: any, status: any) => {
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const body = {
      isActive: !status,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };
    const url = API + `productactive/${id}`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success === true) {
          fetchProductList();
          toast.success(
            `Product ${!status ? "Is Active" : "Is Inactive"} Successfully.`
          );
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  React.useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  return (
    <>
      <DashboardHeader title={"Products"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <div className="mb-5 flex items-center justify-between">
          <h2 className="hidden text-xl font-bold md:block">Products</h2>
          <div className="flex items-center">
            <div className="relative mx-5 h-10 w-full xl:w-auto">
              <SearchIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
              <input
                type="search"
                className="w-full rounded-md border-none bg-cultured pl-9 font-light placeholder:font-light placeholder:text-gray focus:ring-0 xl:w-[300px]"
                placeholder="Search..."
                onChange={(e) => setSearchProduct(e.target.value)}
              />
            </div>
            {/* {!userShopDetails?.isIntegration && (
              <button
                className="mx-5 w-fit select-none rounded-md border border-gold bg-gold py-2 px-5 font-medium capitalize text-raisin-black"
                onClick={() => navigate(`/addProduct`)}
              >
                Add Product
              </button>
            )} */}
          </div>
        </div>
        {Object.keys(linksDisplay).map((key, index) => {
          return (
            <div key={index}>
              <h3 className="my-4 text-2xl font-bold capitalize">
                {key}{" "}
                <span className="text-sm">({linksDisplay[key]?.length})</span>
              </h3>
              <ul className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                {linksDisplay[key]
                  ?.sort((a: any, b: any) => a?.name.localeCompare(b?.name))
                  ?.map((items, index) => {
                    return (
                      <ProductBox
                        product={items}
                        key={index}
                        onLoading={isLoading}
                        onDelete={openDeleteModal}
                        onUpdate={onUpdateProduct}
                        onStock={onProductStockControl}
                        onActive={onActiveProduct}
                        icon={false}
                        cart={false}
                      />
                    );
                  })}
              </ul>
            </div>
          );
        })}
      </section>

      <Transition appear show={isDeleteModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeDeleteModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 text-lg font-medium leading-6"
                  >
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 text-sm">
                      Are you sure you want to Delete?
                    </p>
                  </div>

                  <div className="mt-4 flex space-x-3">
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={onDeleteProduct}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={closeDeleteModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Product;
