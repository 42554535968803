import React from "react";
import axios from "axios";
import API from "config/Api";
import { ShopCategorie, ShpoLocalstorage } from "types";
import { HashLink } from "react-router-hash-link";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { BarsArrowDownIcon, BarsArrowUpIcon } from "components/icons";
import { ChevronRightIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import { AppCtx } from "data/context";

interface Props {
  localCartData: any;
}

const BottonNavigation: React.FC<Props> = ({ localCartData }) => {
  const { setIsStaffOrderView } = React.useContext(AppCtx);
  const [categorieOpen, setCategorieOpen] = React.useState(false);
  const [categorieList, setCategorieList] = React.useState<ShopCategorie | []>(
    []
  );
  const ref = React.useRef<any>();

  //FETCH CATEGORIES RESPECTED TO STORE
  const fetchShopCategorieList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(
      localStorage.getItem("jode_shop")!
    ) as ShpoLocalstorage;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
      },
    };

    const url = API + `category/getallbystore/${authData?.id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setCategorieList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, []);

  useOutsideClick(ref, () => {
    if (categorieOpen) {
      setCategorieOpen(false);
    }
  });

  //INITIAL VALUE
  React.useEffect(() => {
    fetchShopCategorieList();
  }, [fetchShopCategorieList]);

  return (
    <div className="sticky bottom-5  mx-auto h-12 w-full rounded-lg  bg-gold px-4 shadow-xl lg:w-[48rem]">
      <div className="relative flex items-center justify-between gap-4">
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => setIsStaffOrderView(true)}
        >
          <div className="select-none text-raisin-black">
            <p className="absolute top-2 left-1 ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-raisin-black text-xs text-white">
              {localCartData?.product?.length || 0}
            </p>
            <ShoppingCartIcon className="h-6 w-6 select-none" />
          </div>
          <span className="text-2xl font-extrabold">|</span>
          <h2 className="text-xl font-bold">
            ₹ {localCartData?.totalPrice || 0}/-
          </h2>
        </div>
        {categorieOpen && (
          <div
            ref={ref}
            className="absolute bottom-12 left-1/2 mx-auto grid h-96 w-72 -translate-x-1/2 transform overflow-auto rounded-lg bg-raisin-black text-white scrollbar-hide"
          >
            <div className="select-none px-6">
              {categorieList
                ?.sort((a: any, b: any) => (a?.priority > b?.priority ? 1 : -1))
                .filter((active) => active.is_active)
                .map((items, index) => {
                  return (
                    <h2 className="border-b-2 border-white/20 p-2" key={index}>
                      <HashLink
                        smooth
                        to={`#${items?.name}`}
                        onClick={() => setCategorieOpen(!categorieOpen)}
                      >
                        {items?.name}
                      </HashLink>
                    </h2>
                  );
                })}
            </div>
          </div>
        )}
        <div
          onClick={() => setCategorieOpen(!categorieOpen)}
          className="z-50 -mt-4 flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border-[2px] border-raisin-black bg-white"
        >
          {categorieOpen ? (
            <BarsArrowDownIcon className="h-9 w-9 select-none text-raisin-black" />
          ) : (
            <BarsArrowUpIcon className="h-9 w-9 select-none text-raisin-black" />
          )}
        </div>
        <div
          className="flex cursor-pointer items-center gap-0"
          onClick={() => setIsStaffOrderView(true)}
        >
          <h2 className="text-lg font-extrabold">Create KOT</h2>
          <ChevronRightIcon className="h-6 w-6 select-none text-raisin-black" />
        </div>
      </div>
    </div>
  );
};

export default BottonNavigation;
